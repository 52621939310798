// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import BaseView from '@/classes/base.view';
import template from './template.ejs';
import TableView from '@/blocks/layout/b-block-table/table';
import TableFooterView from '@/blocks/layout/b-block-table/table_footer';
import axios from 'axios';

import GlUl from '@/utils/global-utils';
import $ from 'jquery';

export default BaseView.extend({

	template,

	events: {
		'click .p-search__submit': 'apply',
		'click .b-employees-add': 'addEmployee',
		'click .b-block-container__filters-trigger': 'toggleFilters',
		'click .js-scroll-btn': 'scrollTo',
	},

	initialize() {
		const { data } = this.options;
		const {createPersonAllowed} = STATE[STATE.checkSiteType('B2C') ? 'getLoginUser' : 'getProfile']() || {};
		this.options.createPersonAllowed = createPersonAllowed;
		this.tablePageHistory = [0];

		this.apply = this.apply.bind(this);
		this.model.on('change:category', (model) => {
			model.unset('productType');
			model.unset('client');
			this.render();
		});
		this.model.on('change:page', (model, value) => {
			if (this.tablePageHistory) this.tablePageHistory.push(value);
			this.apply();
		});
		this.model.on('afterchange:pageSize', this.apply);
		this.model.on('change', (model) => {
			const changed = _.omit(model.changed, ['page', 'count', 'pageSize']);
			if (_.isEmpty(changed)) return;
			GlUl.unsetPageOnFilterChange.call(this, model);
		});
		this.render();
		this.addEventListeners();

		this.windowEventListenerList.push({
			name: 'keydown',
			callback: GlUl.tablePageHistoryHandler.bind(this),
		});

		this.addWindowEventListeners();

		this.adjustMobileTemplate(STATE.checkViewport('(max-width: 768px)'));

		if (!_.isEmpty(data)) {
			this.applyCallback(data);
		}
	},

	onKeyUpApply(e) {
		clearTimeout(this.keyUpTimer);
		this.keyUpTimer = setTimeout(() => {
			if (e && e.which === 13) {
				$(e.currentTarget).trigger('blur');
				this.apply();
				$(e.currentTarget).trigger('focus');
			}
		});
	},

	addEventListeners() {
		this.onKeyUpApply = this.onKeyUpApply.bind(this);
		this.$('.b-input__value').keyup(this.onKeyUpApply);
	},

	undelegateEvents(...args) {
		if (this.$el) {
			this.$('.b-input__value').off('keyup', this.onKeyUpApply);
		}
		BaseView.prototype.undelegateEvents.apply(this, args);
	},

	addEmployee(e) {
		if (e) {
			e.preventDefault();
		}

		STATE.navigate(`/${STATE.ROUTES.CABINET_EMPLOYEE}/new`);
	},

	apply(e) {
		if (e && _.isFunction(e.preventDefault)) {
			e.preventDefault();
		}

		if ($('.l-layout').hasClass('show-filters')) this.toggleFilters();

		STATE.showLoader();
		this.disableElements(e);
		axios.post('/midoffice/ibecorp-b2b/cabinet/getEmployees', this.model.toJSON()).then(this.applyCallback.bind(this));
	},

	showMoreEventHandler(e) {
		const count = this.model.get('pageSize');
		if (count >= this.model.get('count')) {
			if (e) $(e.currentTarget).hide();
			return;
		}
		this.model.set('pageSize', count + 10);
		this.apply(e);
	},

	scrollTo(e) {
		const scrollTop = $(e.currentTarget).hasClass('top');
		$(e.currentTarget).toggleClass('top', !scrollTop);

		if (scrollTop) {
			this.previousScrollPosition = $(window).scrollTop();
			$(window).scrollTop(0);
		} else {
			$(window).scrollTop(this.previousScrollPosition);
		}
	},

	applyCallback(response) {
		STATE.hideLoader();

		const result = response.data.result;
		const employees = result.employees;
		const isMobileTemplate = STATE.checkViewport('(max-width: 768px)');
		this.model.set('count', result.count);
		this.$('.b-block-container__footer').html(new TableFooterView({
			model: this.model,
			isMobileTemplate,
			showMoreEventHandler: this.showMoreEventHandler.bind(this),
			data: {
				count: result.count,
			},
		}).el);
		this.$('.b-block-container__content').html(new TableView({
			clickField: {
				value: 'personRef.uid',
				path: 'cabinet/employee/',
				linkFieldName: 'fullName',
			},
			fields: [
				{
					name: 'fullName',
					caption: L10N.get('cabinet.employees.fullName'),
					formatter: (fullName) => {
						if (!fullName) {
							return '----------';
						}

						const names = fullName.split(' ');

						return `<div class="first-name">${names[0]}</div> ${names.slice(1).join(' ')}`;
					},
				},
				{
					name: 'clients',
					caption: L10N.get('cabinet.employees.clientCompany'),
					iconClass: 'g-icon-organization-blue',
					formatter: (clients) => {
						if (!_.isEmpty(clients)) {
							return clients[0].caption;
						}

						return '----------';
					},
				},
				{
					name: 'birthDate',
					caption: L10N.get('cabinet.employees.birthDate'),
					formatter: (date) => {
						if (!date || date === '') {
							return '----';
						}
						const momentDate = new Time(date);
						return momentDate.toDateString();
					},
				},
				{
					name: 'documents',
					caption: L10N.get('cabinet.employees.documents'),
					tooltip: true,
					formatter: (documents) => {
						if (!_.isEmpty(documents)) {
							const docs = [];

							_.each(documents, (document) => {
								let docString = '';
								if (document.number) {
									if (document.type != null) {
										docString += `<span class="document-type">${document.type.caption}</span> `;
									}

									docString += `№${document.number}`;
									docs.push(`<div>${docString}</div>`);
								}
							});

							return docs.join('');
						}

						return '—';
					},
				},
				{
					name: 'emails',
					caption: L10N.get('cabinet.employees.emails'),
					formatter: (emails) => {
						if (!_.isEmpty(emails)) {
							return emails[0];
						}

						return '—';
					},
				},
				{
					name: 'phones',
					caption: L10N.get('cabinet.employees.phones'),
					formatter: (phones) => {
						if (!_.isEmpty(phones) && phones[0].countryCode && phones[0].cityCode && phones[0].number) {
							return `+${phones[0].countryCode} (${phones[0].cityCode}) ${phones[0].number}`;
						}

						return '—';
					},
				},
			],
			isMobileTemplate,
			data: employees,
		}).el);
	},

	adjustMobileTemplate(matches) {
		clearTimeout(this.timer);
		if (_.isObject(matches)) matches = matches.matches;
		this.timer = setTimeout(() => {
			const $cabinetFilters = $('.b-cabinet__filters');
			const $topControls = this.$('.b-block-container__top-controls');
			const $passengerAddBtn = this.$('.js-passenger-add');
			const $topControlsElements = $topControls.find(
				'.b-block-container__element:not(.b-block-container__element--search)',
			);

			if (matches) {
				_.each($topControlsElements, (el) => el && $cabinetFilters.append($(el).detach()));
				this.$('.js-passenger-add-mobile-container').append($passengerAddBtn);
				$cabinetFilters.find('.p-search__submit').on('click.filtersApply', this.apply.bind(this));
			} else {
				$cabinetFilters.find('.p-search__submit').off('click.filtersApply', this.apply.bind(this));
				this.$('.js-passenger-add-container').append($passengerAddBtn);
				_.each($cabinetFilters.find('.b-block-container__element'), (el) => {
					$topControls.append($(el).detach());
				});
			}
		}, 100);
	},

	toggleFilters() {
		const $lLayout = $('.l-layout');
		$lLayout.toggleClass('show-filters', !$lLayout.hasClass('show-filters'));
	},

});
