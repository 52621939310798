module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="b-hotel-room">\n	<div class="b-hotel-room__wrapper">\n		<div class="b-hotel-room__header-wrapper">\n			<div class="b-hotel-room__header">' +
((__t = ( (index + 1))) == null ? '' : __t) +
' ' +
((__t = ( L10N.get('hotels.room') )) == null ? '' : __t) +
'</div>\n		</div>\n		<div class="b-hotel-room__row">\n			<div class="b-hotel-room__content">\n				<div class="b-hotel-room__content-header">\n					<div class="b-hotel-room__content-leftbar">\n						<span class="b-hotel-room__name">' +
((__t = ( offer.roomName )) == null ? '' : __t) +
'</span>\n\n					';
 if (offer.online !== true) { ;
__p += '\n						<div class="b-notification b-hotel-room__content-notification ' +
((__t = ( offer.offerWithPenalty !== true ? ' b-notification--type-alert' : '' )) == null ? '' : __t) +
'">\n							<div><strong>' +
((__t = ( L10N.get('hotels.warning') )) == null ? '' : __t) +
'!</strong> ' +
((__t = ( L10N.get('hotels.answerCanTakeLongTime') )) == null ? '' : __t) +
'.</div>\n							<div>' +
((__t = ( L10N.get('hotels.onConfirmMayBePenalties') )) == null ? '' : __t) +
'.</div>\n						</div>\n					';
 } ;
__p += '\n					</div>\n				</div>\n				<div class="b-hotel-room__content-description">\n					';
 if (offer.cancellationPolicyText || offer.offerWithPenalty === true) { ;
__p += '\n						<div class="b-hotel-room__toggle-caption-wrapper">\n							<div class="b-hotel-room__toggle-caption collapse--with-anchor mobile-collapse ' +
((__t = ( offer.offerWithPenalty === true ? 'b-hotel-room__toggle-caption--type-alert' : 'collapsed' )) == null ? '' : __t) +
'" data-toggle="collapse" data-target=".toggle-booking-container-description-0">\n								' +
((__t = ( L10N.get('hotels.penaltiesFull') )) == null ? '' : __t) +
'\n							</div>\n						</div>\n						<div class="toggle-booking-container-description-0 b-hotel-room__toggle-container collapse ' +
((__t = ( offer.offerWithPenalty === true ? 'show' : '' )) == null ? '' : __t) +
'">\n                            ';
 if (!_.isEmpty(offer.essentialInfoList)) {
                                const result = {};
                                _.each(offer.essentialInfoList, function(el) {
                                    if (!el) return;
                                    if (!result[el.type.uid]) {
                                        result[el.type.uid] = el.infoValue;
                                        return;
                                    }
                                    result[el.type.uid].push(...el.infoValue);
                                });
                            ;
__p += '\n                            <div class="b-hotel-room__toggle-container-essantial">\n                                ';
 _.each(_.keys(result), function (key) {
                                    const value = _.sortBy(result[key], function(el) { return el.length});
                                if (key === "ADDITIONAL_INFORMATION") { ;
__p += '\n                                    <span class="b-hotel-offer__information" data-toggle="tooltip" data-html="true" data-original-title="<div>' +
((__t = ( _.escape(_.map(value).join('</br></br>')) )) == null ? '' : __t) +
'</div>">';
 ;
__p += '</span>\n                                ';
 }
                                if (key === "CHECK_IN_POLICIES") { ;
__p += '\n                                    <span class="b-hotel-offer__passport" data-toggle="tooltip" data-html="true" data-original-title="<div>' +
((__t = ( _.escape(_.map(value).join('</br></br>')) )) == null ? '' : __t) +
'</div>">';
 ;
__p += '</span>\n                                ';
 }
                                if (key === "ANTI_COVID_PROGRAM") { ;
__p += '\n                                    <span class="b-hotel-offer__anti-covid" data-toggle="tooltip" data-html="true" data-original-title="<div>' +
((__t = ( _.escape(_.map(value).join('</br></br>')) )) == null ? '' : __t) +
'</div>">';
 ;
__p += '</span>\n                                ';
 }
                                if (key === "AGENCY_INFORMATION") { ;
__p += '\n                                    <span class="b-hotel-offer__agencyInfo" data-toggle="tooltip" data-html="true" data-original-title="<div>' +
((__t = ( _.escape(_.map(value).join('</br></br>')) )) == null ? '' : __t) +
'</div>">';
 ;
__p += '</span>\n                                ';
 }
                                });
__p += '\n                            </div>\n                            ';
} ;
__p += '\n							';
 if (offer.offerWithPenalty === true && offer.freeCancellationTerm == null) { ;
__p += '\n								<div class="b-hotel-room__toggle-container-penalty">\n									<div>\n										<strong>' +
((__t = ( L10N.get('hotels.warning') )) == null ? '' : __t) +
': </strong> ' +
((__t = ( L10N.get('hotels.cancellationWithoutPenalties') )) == null ? '' : __t) +
' <span class="b-hotel-room__important">' +
((__t = ( L10N.get('hotels.isImpossible') )) == null ? '' : __t) +
'</span>\n									</div>\n								</div>\n							';
 } ;
__p += '\n							<div class="b-hotel-room__toggle-container-inner">\n								' +
((__t = ( offer.cancellationPolicyText )) == null ? '' : __t) +
'\n							</div>\n						</div>\n					';
 } ;
__p += '\n\n					';
 if (offer.meal != null || offer.standardCheckIn || offer.standardCheckOut) { ;
__p += '\n						<div class="b-hotel-room__info-wrapper">\n							';
 if (offer.standardCheckIn != null) { ;
__p += '\n								<div class="b-hotel-room__info-item">\n									<span class="b-hotel-room__info-service">' +
((__t = ( L10N.get('hotels.checkin') )) == null ? '' : __t) +
'</span>\n									<span class="b-hotel-room__info-value">' +
((__t = ( new Time(offer.standardCheckIn).toShortTime() )) == null ? '' : __t) +
'</span>\n								</div>\n							';
 } ;
__p += '\n\n							';
 if (offer.standardCheckOut != null) { ;
__p += '\n								<div class="b-hotel-room__info-item">\n									<span class="b-hotel-room__info-service">' +
((__t = ( L10N.get('hotels.checkout') )) == null ? '' : __t) +
'</span>\n									<span class="b-hotel-room__info-value">' +
((__t = ( new Time(offer.standardCheckOut).toShortTime() )) == null ? '' : __t) +
'</span>\n								</div>\n							';
 } ;
__p += '\n\n							';
 if (offer.meal != null) { ;
__p += '\n								<div class="b-hotel-room__info-item">\n									<span class="b-hotel-room__info-service">' +
((__t = ( L10N.get('hotels.meal') )) == null ? '' : __t) +
'</span>\n									<span class="b-hotel-room__info-value">\n										' +
((__t = ( (offer.meal != null && !['-1', 'nomeal'].includes(offer.meal.uid) && !['-', L10N.get('hotels.withoutMeal')].includes(offer.meal.caption)) ? offer.meal.caption : L10N.get('hotels.withoutMeal') )) == null ? '' : __t) +
'\n									</span>\n								</div>\n							';
 } ;
__p += '\n						</div>\n					';
 } ;
__p += '\n				</div>\n			</div>\n			';
 if (_.some(offer.options, function (o) {
				return _.isArray(o) && !_.isEmpty(o);
			})) { ;
__p += '\n				<div class="b-hotel-room__additional-services"></div>\n			';
 } ;
__p += '\n		</div>\n        ';
 if (offer.remarks && offer.remarks.length) { ;
__p += '\n            <div class="b-hotel-room__row">\n                <div class="b-hotel-room__offer-remarks js-remarks-container">\n                    <div class="b-hotel-room__offer-remarks-header">\n                        ' +
((__t = ( L10N.get('hotels.additionalInfo'))) == null ? '' : __t) +
'\n                        <span class="g-icon-attention" data-toggle="tooltip" data-html="true" data-position="bottom" data-original-title="<div>' +
((__t = ( L10N.get('hotels.additionalInfoTooltip') )) == null ? '' : __t) +
'</div>"></span>\n                    </div>\n                </div>\n            </div>\n        ';
 } ;
__p += '\n	</div>\n	<div class="b-hotel-room__travellers b-hotel-travellers">\n		<div class="b-hotel-travellers__header-wrapper">\n			<div class="b-hotel-travellers__header collapse--with-anchor--white mobile-collapse" data-toggle="collapse" data-target=".toggle-travellers-';
 index ;
__p += '">' +
((__t = ( L10N.get('hotels.guests') )) == null ? '' : __t) +
'</div>\n		</div>\n\n		<div class="b-hotel-travellers__wrapper toggle-travellers-';
 index ;
__p += ' show"></div>\n	</div>\n</div>\n';

}
return __p
}