// eslint-disable-next-line banned-modules
'use strict';

import BasePageView from '@/pages/base.page-view';
import CabinetMenu from '@/blocks/pages/b-cabinet/b-cabinet-menu/index';
import PaymentCashBlockView from '@/blocks/elements/b-payment/b-payment-cash/index';
import Order from '@/blocks/pages/b-cabinet/b-cabinet-orders/b-cabinet-order/index';
import OrderModel from './model';
import PaymentPopupTemplate from '@/blocks/pages/b-cabinet/b-cabinet-orders/b-cabinet-order/template-payment';
import Gmaps from '@/blocks/utils/b-google-maps/index';
import BookingDuplicatesHandler from '@/blocks/utils/b-booking-duplicates-handler';

import Backbone from 'backbone';
import $ from 'jquery';
import axios from 'axios';
import Formatter from '@/utils/formatter';

const CabinetOrderView = BasePageView.extend({

	events: {
		'click .b-payment__pay': 'initiatePayment',
	},

	initialize(options) {
		const {checkBooking, currentBooking, messages} = options;
		this.reservations = currentBooking.order.reservations;
		this.initiatePaymentToken = undefined;

		this.bookingDuplicatesHandler = new BookingDuplicatesHandler();

		this.model = new OrderModel();
		this.render();

		if (!_.isEmpty(checkBooking)) {
			this.model.set('checkBooking', checkBooking);
			this.model.get('initiatePayment').set('bookingToPay', checkBooking.bookingInfo.bookingUid);
		}
			
		this.ui.sidebar.html(new CabinetMenu().$el);
		this.ui.content.html(new Order({
			model: this.model,
			order: currentBooking.order,
			booking: checkBooking,
			messages,
			parentView: this,
		}).$el);

		this.listenTo(this.model.get('initiatePayment'), `change:paymentType`, (thisModel, value) => {
			// слушается изменение initiatePayment в котором содержиться цена только последнего билета!
			// Эта же цена ставится в visible price
			const paymentType = _.find(this.model.get('checkBooking').paymentInfo, (el) => el[value] != null && el[value].price != null);
			this.model.get('initiatePayment').set('visiblePrice', !_.isEmpty(paymentType) ? paymentType[value].price.amount : null);

			const hasSabrePaymentType = checkBooking != null &&
				checkBooking.paymentInfo != null &&
				!_.isEmpty(checkBooking.paymentInfo.sabrePaymentType) &&
				!_.isEmpty(checkBooking.paymentInfo.sabrePaymentType.cards);

			if (value && (!hasSabrePaymentType || (hasSabrePaymentType && thisModel.get('sabreCard')))) {
				this.$('.b-payment__pay').removeClass('inactive btn-disabled').removeAttr('disabled');
			} else {
				this.$('.b-payment__pay').addClass('inactive btn-disabled').attr('disabled', 'disabled');
			}
		});

		this.listenTo(this.model.get('initiatePayment'), `change:sabreCard`, (thisModel, value) => {
			if (value) {
				this.$('.b-payment__pay').removeClass('inactive btn-disabled').removeAttr('disabled');
			} else {
				this.$('.b-payment__pay').addClass('inactive btn-disabled').attr('disabled', 'disabled');
			}
		});
	},

	isRailwayRoundtrip(reservation) {
		const {uid, mainReservation, travelSubject, prn} = reservation;
		return travelSubject.uid === 'RAILWAY' && _.isArray(prn) && mainReservation != null && uid !== mainReservation;
	},

	initiatePayment(e) {
		this.$el.find('.b-order__payment-pay').addClass('disabled').attr('disabled', 'disabled');

		STATE.showLoader();
		this.model.get('initiatePayment').set('paymentType', this.$('[name="initiatePayment.paymentType"]:checked').attr('value'));
		logger.debug('initiatePayment', this.model.get('initiatePayment').toJSON());

		const reservationsToPay = {};
		const reservationsWithChangedPrice = this.model.get('reservationsWithChangedPrice');
		let visiblePriceOfReservationsWithChangedPrice = 0;

		if (reservationsWithChangedPrice && reservationsWithChangedPrice.length) {
			_.each(reservationsWithChangedPrice, (reservationWithChangedPrice) => {
				reservationsToPay[reservationWithChangedPrice.uid] = reservationWithChangedPrice.price;
				const visiblePriceOfReservationsWithChangedPriceNumber = parseFloat(visiblePriceOfReservationsWithChangedPrice);
				const priceToAdd = parseFloat(reservationWithChangedPrice.price);
				visiblePriceOfReservationsWithChangedPrice = (
					visiblePriceOfReservationsWithChangedPriceNumber + priceToAdd
				).toFixed(2);
			});
			this.model.get('initiatePayment').set('visiblePrice', visiblePriceOfReservationsWithChangedPrice);
		} else {
			_.each(this.reservations, (reservation) => {
				const uid = reservation.uid;
				const isRoundTripRailway = this.isRailwayRoundtrip(reservation);
				const isHotelProposal = reservation.travelSubject.uid === 'HOTEL_RESERVATION' && reservation.status.uid === 'PROPOSAL';

				_.each(this.model.get('selectedReservation'), (selectedReservation) => {
					if ((selectedReservation.get('uid') === uid) && selectedReservation.get('selected')) {
						if (isRoundTripRailway) {
							_.each(reservation.prn, (prn) => {
								reservationsToPay[prn.uid] = prn.price.total.amount;
							});
							return;
						}
						if (isHotelProposal) return;
						reservationsToPay[uid] = reservation.totalPrice.amount;
					}
				});
			});
		}

		this.model.get('initiatePayment').set('reservationsToPay', reservationsToPay);

		const {
			acceptProductDuplicatesMessageId,
		} = this.bookingDuplicatesHandler.getBookingDataObj();

		const params = this.model.get('initiatePayment').toJSON();
		params.parameters = {
			...params.parameters,
			acceptProductDuplicatesMessageId,
			initiatePaymentToken: this.initiatePaymentToken,
		};

		this.disableElements(e);
		axios.post('/midoffice/ibecorp-b2b/booking/initiatePayment', params, this.model.get('initiatePayment'))
			.then((result) => {
				STATE.hideLoader();

				if (result.data.result?.initiatePaymentToken && result.data.result?.productDuplicatesMessage) {
					this.bookingDuplicatesHandler.showProductDuplicatesPopup({
						productDuplicatesMessage: result.data.result.productDuplicatesMessage,
						onOk: () => {
							this.initiatePaymentToken = result.data.result.initiatePaymentToken;
							this.initiatePayment(e); // <- событие нужно для того, чтобы с его target считать данные,
						},
					});
					return result;
				}
				
				const priceChangeInfo = result.data.result.priceChangeInfo;
				if (priceChangeInfo != null && Object.keys(priceChangeInfo)
					.some((reservationId) => priceChangeInfo[reservationId].priceChanged === true)) {
					this.repricePopup(result.data.result.priceChangeInfo, this.reservations);
					return result;
				}
				if (result.data.result.initiatePaymentInfo) {
					this.ui.content.empty();
					const paymentCashBlockView = new PaymentCashBlockView(result.data.result.initiatePaymentInfo);
					this.ui.content.append(paymentCashBlockView.$el);
					return result;
				}
				let parameters = result.data.result.parameters;
				if (!parameters) {
					parameters = {};
				}
				parameters.bookingUid = this.model.get('initiatePayment').get('bookingToPay');
				if (result.data.result.paymentId) {
					parameters.paymentUid = result.data.result.paymentId;
				}
				if (result.data.result.method && result.data.result.method.toLowerCase() !== 'get') {
					const $paymentForm = $(`<form action="${result.data.result.url}" method="${result.data.result.method}" />`);
					$(document.body).append($paymentForm);
					Object.keys(parameters).reduce(($form, key) => {
						$form.append(`<input type="hidden" name="${key}" value="${parameters[key]}" />`);
						return $form;
					}, $paymentForm);
					$paymentForm[0].submit();
				} else if (result.data.result.url != null) {
					const lang = STATE.getLanguage();
					let resultUrl = result.data.result.url;
					if (resultUrl[0] === '/') {
						resultUrl = `${window.location.protocol}//${window.location.host}/?locale=${lang}${resultUrl.slice(1)}`;
					}
					if (window.location.href === resultUrl) {
						window.location.reload(true);
					} else {
						window.location = resultUrl;
					}
				} else {
					throw Error('no initiatePayment data');
				}
				return result;
			})
			.catch(() => {
				setTimeout(() => {
					const $errorPopupActionBtn = $('.b-popup__action[data-id="0"]');
					$errorPopupActionBtn.on('click', () => window.location.reload(true));
				}, 200);
			})
			.finally(() => {
				this.$el.find('.b-order__payment-pay').removeClass('disabled').removeAttr('disabled');
			});
	},

	async cancelPayment(e) {
		STATE.showLoader();
		try {
			this.disableElements(e);
			await axios.post('/midoffice/ibecorp-b2b/booking/cancelBooking', {
				parameters: {
					bookingToCancel: {
						uid: this.model.get('initiatePayment').get('bookingToPay'),
						caption: '',
					},
				},
			});
			window.location.reload(true);
		} catch (error) {
			Widgets.Popup.showUnknownErrorPopup();
		}

		STATE.hideLoader();
	},

	getReservationsWithChangedPrice(priceChangeInfo) {
		const reservations = this.model.get('initiatePayment').get('reservationsToPay');
		const reservationsWithChangedPrice = [];
		if (!_.isEmpty(priceChangeInfo.booking)) {
			_.each(Object.keys(reservations), (uid) => {
				if (priceChangeInfo.booking.priceChanged) {
					reservationsWithChangedPrice.push({
						price: priceChangeInfo.booking.newPrice.amount,
						uid,
					});
				}
			});
		} else {
			_.each(Object.keys(priceChangeInfo), (uid) => {
				const reservation = priceChangeInfo[uid];
				if (reservation.priceChanged) {
					reservationsWithChangedPrice.push({
						price: priceChangeInfo[uid].newPrice.amount,
						uid,
					});
				}
			});
		}
		
		return reservationsWithChangedPrice;
	},

	repricePopup(priceChangeInfo, reservations) {
		const getPrnById = (uid) => {
			const el = reservations.find((r) => r.uid === uid);
			if (Array.isArray(el.prn)) {
				const result = [];
				_.each(el.prn, (item) => item.prn && result.push(item.prn));
				result.join(', ');
				return result;
			}
			return el ? el.prn : '';
		};

		const getMessage = ({id, reservation, ...rest}) => {
			const message = _.template(`<div>
				<% if (id != 'booking') { %>
				${L10N.get('cabinet.orders.searchByBookingNr')}
					<%= getPrnById(id) + ': '%>
				<% } %>
				${L10N.get('cabinet.orders.actualPrice')}: <strong>
					<%= reservation.newPrice.amount %><%= Formatter.formatCurrency(reservation.newPrice.currencyCode) %>
				</strong>
				(${L10N.get('cabinet.orders.prevPrice')}: <%= reservation.oldPrice.amount %><%= Formatter.formatCurrency(reservation.oldPrice.currencyCode) %>)
				<br/>
			</div>`);

			return message({id, reservation, ...rest});
		};

		const messages = Object.keys(priceChangeInfo)
			.filter((reservationId) => priceChangeInfo[reservationId].priceChanged)
			.map((reservationId) => getMessage({
				id: reservationId,
				reservation: priceChangeInfo[reservationId],
				reservations,
				getPrnById,
				Formatter,
			}));

		const repricePopup = new Widgets.Popup({
			content: messages,
			title: L10N.get('cabinet.orders.priceChanging'),
			closeOnlyOnAction: true,
			type: 'info',
			actions: [
				{
					label: L10N.get('cabinet.orders.cancel'),
					action: () => {
						repricePopup.hide();
						window.location.reload();
					},
				},
				{
					label: L10N.get('cabinet.orders.doPayment'),
					action: () => {
						repricePopup.hide();
						this.model.set('reservationsWithChangedPrice', this.getReservationsWithChangedPrice(priceChangeInfo));
						this.initiatePayment();
					},
				},
			],
		});
		repricePopup.show();
	},

	remove() {
		this.bookingDuplicatesHandler.remove();
		BasePageView.prototype.remove.call(this);
	},

}, {

	getParameters() {
		const result = {};
		const parametersIndex = window.location.href.indexOf('?');
		if (parametersIndex > -1) {
			let sPageURL;
			try {
				sPageURL = decodeURIComponent(window.location.href.substr(parametersIndex + 1));
			} catch (e) {
				logger.error(e);
				sPageURL = window.location.href.substr(parametersIndex + 1);
			}
				
			const sURLVariables = sPageURL.split('&');
			for (let i = 0; i < sURLVariables.length; i++) {
				const sParameterName = sURLVariables[i].split('=');
				const indexOfHash = sParameterName[1].indexOf('#');
				let paramValue = sParameterName[1];
				if (indexOfHash > -1) {
					paramValue = paramValue.substr(0, indexOfHash);
				}
				result[sParameterName[0]] = paramValue;
			}
		}
		return result;
	},

	async load(bookingUid) {
		if (!STATE.getCountries()) {
			await STATE.loadCountries();
		}

		return new Promise((resolve, reject) => {
			// is it a comeback from a payment gate?
			const checkPayment = false;

			const getParameters = _.omit(CabinetOrderView.getParameters(), 'locale');
			if (getParameters && getParameters.eform) {
				delete getParameters.eform;
			}
			const params = {
				bookingToCheck: bookingUid,
			};

			if (!bookingUid && !getParameters.bookingUid && !getParameters.booking && !getParameters.pnr) {
				reject({error: 'No order infromation provide.'});
				return;
			}

			if (!_.isEmpty(getParameters)) {
				// checkPayment = true;

				if (getParameters.booking && !getParameters.bookingUid) {
					getParameters.bookingUid = getParameters.booking;
					delete getParameters.booking;
				}

				params.pnrToCheck = getParameters.pnr || params.pnrToCheck;
				params.bookingToCheck = getParameters.bookingUid || params.bookingToCheck;
				params.parameters = getParameters;

				Backbone.history.navigate(`${STATE.ROUTES.CABINET_ORDER}/${getParameters.bookingUid}`, {replace: true});
			}

			const checkedBookingUid = bookingUid || getParameters.bookingUid || params.bookingToCheck;

			const getBookingInfo = (uid) => {
				return axios.post('/midoffice/ibecorp-b2b/booking/getBookingInfo', {
					parameters: {
						currentBooking: uid,
					},
				});
			};

			const checkBookingResult = (getBookingInfoResponse, checkBooking) => {
				const {order} = getBookingInfoResponse.data.result;
				const {reservations} = order;

				let promise = Promise.resolve();
				if (_.some(reservations, (reservation) => reservation.travelSubject && reservation.travelSubject.uid === 'HOTEL_RESERVATION')) {
					if (window.google == null) {
						promise = Gmaps.getApiPromise();
					}
				}

				promise.then(() => {
					resolve({
						checkBooking,
						currentBooking: getBookingInfoResponse.data.result,
						messages: getBookingInfoResponse.data.messages,
					});
				});
			};

			getBookingInfo(checkedBookingUid).then((bookingInfo) => {
				axios.post('/midoffice/ibecorp-b2b/booking/checkBooking', {
					parameters: params,
				}).then(checkBookingResponse => {
					const checkBooking = checkBookingResponse.data.result;

					if (checkPayment === true) {
						const isSuccess = (checkBooking.paymentInfo != null &&
							checkBooking.paymentInfo.status != null &&
							checkBooking.paymentInfo.status.uid === 'SUCCESS');
						const popup = new Widgets.Popup({
							content: PaymentPopupTemplate({
								checkBooking,
							}),
							title: L10N.get('cabinet.orders.payment'),
							closeOnlyOnAction: true,
							type:	(isSuccess ||
									(checkBooking.bookingInfo != null &&
										checkBooking.bookingInfo.status != null &&
										checkBooking.bookingInfo.status.uid === 'ISSUED')) ? 'info' : 'danger',
							actions: [{
								label: L10N.get('cabinet.orders.close'),
								action: () => {
									if (isSuccess) window.location.reload(true);
									popup.hide();
								},
							}],
							classes: 'without-bg-icon',
						});
						popup.show();
					}
					if (checkBooking.bookingInfo && checkBooking.bookingInfo.needReload) {
						getBookingInfo(checkedBookingUid).then((data) => checkBookingResult(data, checkBooking));
					} else {
						checkBookingResult(bookingInfo, checkBooking);
					}
				}).catch(() => checkBookingResult(bookingInfo, {}));
			});
		});
	},
});

export default CabinetOrderView;
