// eslint-disable-next-line banned-modules
'use strict';

import './styles.less';
import BaseView from '@/classes/base.view';
import template from './template.ejs';
import CustomerView from '@/blocks/elements/b-customer/b-customer-avia/index';
import VisaInfoView from '@/blocks/elements/b-visa-info/index';
import TravellerView from '@/blocks/elements/b-traveller/index';
import Rules from '@/blocks/elements/b-fare-rules/index';
import ReasonCodeView from '@/blocks/elements/b-reason-code/index';
import KeyContactView from '@/blocks/elements/b-key-contact/index';
import TravellersCostCodesHandler from '@/blocks/utils/b-travellers-cost-codes-handler';
import { shouldHandleTravellers } from '@/blocks/utils/b-travellers-cost-codes-handler/utils';

export default BaseView.extend({

	el: '.l-page__container',

	ui: {
		customer: '.b-booking__customer',
		visaInfo: '.b-booking__visa-info',
		travellers: '.b-booking__travellers',
		rules: '.b-booking__rules',
		reasonCode: '.b-booking__reason-code',
		keyContact: '.b-booking__key-contact',
	},

	isSeatsEuals(seats, passengers) {
		for (let i = 0; i < seats.length; i++) {
			if (seats[i].count !== passengers.filter(p => p.get('type') === seats[i].type.uid).length) {
				return false;
			}
		}
		return true;
	},

	preinitialize(options) {
		BaseView.prototype.preinitialize.call(this, options);
		this.windowEventListenerList.push({
			mediaQuery: '(max-width: 768px)',
			name: 'change',
			callback: this.adjustMobileTemplate.bind(this),
			isMatchMedia: true,
		});
	},

	initialize(options) {
		const {
			order,
			contactFillingType,
			contactPersonEditGranted,
			customer,
			isMiddleNameRequired,
			reasonCode,
			forbidTravellerUpdate,
			contactAutocompleteAvailable,
			contactPersonDisableFreeTextInput,
			organizationCostCodes,
			corporatePassengers = [],
			langRegistrationFullName,
			passengerTypeDocument = [],
			bonusCardAvailable,
			bookingFormSettingsToken,
		} = options.bookingSettings || {};
		this.keyContact = this.options.keyContact;
		this.reasonCode = reasonCode;
		this.externalSystems = options.bookingSettings.externalSystems;
		this.international = options.flight.international;
		this.parent = options.parent;
		this.forbidTravellerUpdate = forbidTravellerUpdate;

		if (order != null) {
			this.order = order;
			STATE.layout.header.setAdditionalOrder(order);
		}

		this.render();

		if (customer != null) {
			const BaseModel = this.model.get('customer').constructor;
			this.model.set('customer', new BaseModel(_.extend({}, customer, {
				disabled: true,
			})));
		}

		const {seats} = options;

		this.travellerViewList = [];

		if (this.isSeatsEuals(seats, this.model.get('passengers'))) {
			this.model.get('passengers').forEach((passenger, index) => {
				const isContact = index === 0 && (contactFillingType || {}).uid === STATE.FILLING_TYPE.FIRST_TRAVELLER;
				const travellerView = new TravellerView({
					index,
					isContact,
					passengerType: seats.find(seat => seat.type.uid === passenger.get('type')).type,
					forbidTravellerUpdate: this.forbidTravellerUpdate,
					parentModel: this.model.get('passengers'),
					model: passenger,
					isMiddleNameRequired,
					contactPersonEditGranted,
					contactPersonDisableFreeTextInput,
					passenger: corporatePassengers[index],
					carrier: options.flight.carrier,
					gds: options.flight.gds,
					organizationCostCodes,
					langRegistrationFullName,
					passengerTypeDocument,
					bonusCardAvailable,
					bookingFormSettingsToken,
					token: this.options.token,
					transliterationOn: langRegistrationFullName.uid === 'LATIN',
					international: this.international,
					parent: this,
				});
				this.ui.travellers.append(travellerView.$el);
				this.travellerViewList.push(travellerView);
			});
		} else {
			this.model.get('passengers').reset();

			const isPassengerTypeExist = corporatePassengers.some((pas) => {
				return pas.passengerType;
			});

			// Case without passengers types - all persons have ADULT type
			if (!isPassengerTypeExist) {
				seats.sort((a, b) => a.type.uid > b.type.uid).forEach(seat => {
					for (let i = 0; i < seat.count; i++) {
						const isContact = i === 0 && (contactFillingType || {}).uid === STATE.FILLING_TYPE.FIRST_TRAVELLER;
						const travellerView = new TravellerView({
							index: i,
							isContact,
							passengerType: seat.type,
							forbidTravellerUpdate: this.forbidTravellerUpdate,
							parentModel: this.model.get('passengers'),
							isMiddleNameRequired,
							contactPersonEditGranted,
							contactPersonDisableFreeTextInput,
							bonusCardAvailable,
							bookingFormSettingsToken,
							passenger: corporatePassengers[i],
							carrier: options.flight.carrier,
							gds: options.flight.gds,
							organizationCostCodes,
							langRegistrationFullName,
							token: this.options.token,
							passengerTypeDocument,
							transliterationOn: langRegistrationFullName.uid === 'LATIN',
							international: this.international,
							parent: this,
						});
						this.ui.travellers.append(travellerView.$el);
						this.travellerViewList.push(travellerView);
					}
				});
			} else {
				// Перестраиваем массив, чтобы работали другие типы, кроме ADULT
				// IBECORP-3176
				const plainSeats = [];
				seats.forEach((seat) => {
					for (let i = 0; i < seat.count; i++) {
						plainSeats.push(seat.type);
					}
				});

				const sortedSeats = plainSeats.sort((a, b) => {
					if (a.uid < b.uid) return -1;
					if (a.uid > b.uid) return 1;
					return 0;
				});

				const sortedCorporatePassengers = corporatePassengers.slice().sort((a, b) => {
					if (a.passengerType.uid < b.passengerType.uid) return -1;
					if (a.passengerType.uid > b.passengerType.uid) return 1;
					return 0;
				});

				for (let i = 0; i < sortedSeats.length; i++) {
					const isContact = i === 0 && (contactFillingType || {}).uid === STATE.FILLING_TYPE.FIRST_TRAVELLER;
					const travellerView = new TravellerView({
						index: i,
						isContact,
						passengerType: sortedSeats[i],
						forbidTravellerUpdate: this.forbidTravellerUpdate,
						parentModel: this.model.get('passengers'),
						isMiddleNameRequired,
						contactPersonEditGranted,
						contactPersonDisableFreeTextInput,
						passenger: sortedCorporatePassengers[i],
						carrier: options.flight.carrier,
						organizationCostCodes,
						langRegistrationFullName,
						bonusCardAvailable,
						bookingFormSettingsToken,
						token: this.options.token,
						transliterationOn: langRegistrationFullName.uid === 'LATIN',
						passengerTypeDocument,
						international: this.international,
						parent: this,
					});
					this.ui.travellers.append(travellerView.$el);
					this.travellerViewList.push(travellerView);
				}
			}
		}

		// проверяем и инициализиуем обработчик common костКодов пассажиров
		const isAddProductToOrder = !!order?.bookingUid;
		this.checkNInitTravellersCostCodesHandler(organizationCostCodes, isAddProductToOrder);

		const customerView = new CustomerView({
			model: this.model.get('customer'),
			contactAutocompleteAvailable,
			contactPersonDisableFreeTextInput,
			contactFillingType,
			contactPersonEditGranted,
			parentModel: this.model.get('passengers'),
		});
		this.ui.customer.append(customerView.$el);

		this.model.get('passengers').each((item) => {
			this.listenTo(item.get('passport'), `change:lastName`, (thisModel) => {
				if (item.get('isContact')) {
					this.model.get('customer').set('lastName', thisModel.get('lastName'));
				}
			});
			this.listenTo(item.get('passport'), `change:firstName`, (thisModel) => {
				if (item.get('isContact')) {
					this.model.get('customer').set('firstName', thisModel.get('firstName'));
				}
			});
			this.listenTo(item, `change:phone`, (thisModel) => {
				if (item.get('isContact')) {
					this.model.get('customer').set('phone', thisModel.get('phone'));
				}
			});
			this.listenTo(item, `change:email`, (thisModel) => {
				if (item.get('isContact')) {
					this.model.get('customer').set('email', thisModel.get('email'));
				}
			});
			this.listenTo(item, `change:isContact`, (thisModel, value) => {
				if (!value) {
					// Сбрасываем personRef
					const resetPersonRefInModel = (model) => {
						if (model && model.get('personRef') != null) {
							model.set('personRef', undefined);
						}
					};
					resetPersonRefInModel(customerView.model);
					if (this.model.get('customer') != null) {
						resetPersonRefInModel(this.model.get('customer'));
					}
					return;
				}
				this.model.get('passengers').each((otherModel) => {
					if (otherModel.cid !== thisModel.cid) {
						otherModel.set('isContact', false);
					}
				});

				const lastName = thisModel.get('passport').get('lastName');

				this.model.get('customer').set('lastName', lastName);
				this.model.get('customer').set('firstName', thisModel.get('passport').get('firstName'));
				this.model.get('customer').set('phone', thisModel.get('phone'));
				this.model.get('customer').set('email', thisModel.get('email'));

				if (lastName && thisModel.get('uid')) {
					const personRef = { uid: thisModel.get('uid'), caption: `${lastName}`};
					customerView.model.set('personRef', personRef);
					this.model.get('customer').set('personRef', personRef);
				}

				if (this.model.get('customer').get('disabled') === true) {
					customerView.allowEdit();
				}
			});
		});

		const docoDocaInfo = this.options.flight.docoDocaNeededInfo;
		if (docoDocaInfo != null && (docoDocaInfo.isDocaNeeded || docoDocaInfo.isDocoNeeded)) {
			const visaInfoView = new VisaInfoView({
				fromEmail: false,
				filled: false,
				model: this.model,
				seats: options.seats,
				passengersModel: this.model.get('passengers'),
				country: this.options.flight.docoDocaNeededInfo.country,
				isDocoNeeded: this.options.flight.docoDocaNeededInfo.isDocoNeeded,
				isDocaNeeded: this.options.flight.docoDocaNeededInfo.isDocaNeeded,
			});
			this.ui.visaInfo.append(visaInfoView.$el);
		}

		const rules = new Rules({
			model: this.model,
			token: this.options.token,
			travelSubjectUid: 'AIR',
		});

		this.ui.rules.html(rules.el);
	},

	checkNInitTravellersCostCodesHandler(organizationCostCodes = [], isAddProductToOrder = false) {
		/*
			Инициализируем обработчик только если у нас есть список костКодов.
			Считаем, что придти он должен в пропсах и если пуст, значит мы имеет дело с розничным
			пассажиром. А у розницы не должно быть костКодов.

			Проверяем, нужно ли нам запустить слушатели изменений в коллекциях костКодов.
			Запускать не нужно:
			1) если хотя бы у одного пассажира есть собственное значение костКода;
			2) если у нас всего один пассажир.
		*/
		if (organizationCostCodes.length
			&& this.travellerViewList.length > 1
			&& shouldHandleTravellers(this.travellerViewList, organizationCostCodes, isAddProductToOrder)) {
			this.travellersCostCodesHandler = new TravellersCostCodesHandler({
				disableAllFilledCommons: isAddProductToOrder,
			});
      
			// добавляем в обработчик вьюшки путешественников и их модели
			this.travellerViewList.forEach((view) => {
				this.travellersCostCodesHandler.addTravellerView(view);
			});
		}
	},

	delegateEvents(...args) {
		BaseView.prototype.delegateEvents.apply(this, args);
	},

	undelegateEvents(...args) {
		BaseView.prototype.undelegateEvents.apply(this, args);
	},

	adjustMobileTemplate(matches) {
		clearTimeout(this.timer);
		this.timer = setTimeout(() => {
			if (_.isObject(matches)) matches = matches.matches;
			const $sideBar = this.parent.ui.sidebar;
			let flightInfoView = this.parent.flightInfoView;
			const $mobileCollapseHeaders = this.$('.mobile-collapse');
			const $tooltips = this.$('[data-toggle="tooltip"]');

			if (matches) {
				this.$('.js-price-info-container').append(flightInfoView.ui.pricing);
				_.each($mobileCollapseHeaders, (el) => el && this.$(el).attr('data-toggle', 'collapse'));
				_.each($tooltips, (el) => el && this.$(el).tooltip('disable'));
			} else {
				flightInfoView = this.$('.js-price-info-container').find('.b-flight-info__pricing');
				$sideBar.find('.b-flight-info').append(flightInfoView);
				_.each($mobileCollapseHeaders, (el) => {
					const $target = this.$(el);
					if ($target.hasClass('collapsed')) $target.click();
					$target.removeAttr('data-toggle');
				});
				_.each($tooltips, (el) => el && this.$(el).tooltip('enable'));
			}

			_.each(this.travellerViewList, (v) => v && v.adjustMobileTemplate(matches));
		}, 100);
	},

	render() {
		const {reasonCode} = this.options.bookingSettings || {};

		const container = this.$el;
		this.setElement(template.call(this));
		container.append(this.$el);

		if (reasonCode && reasonCode.values) {
			this.ui.reasonCode.html(new ReasonCodeView({
				model: this.model,
				reasonCode,
			}).$el);
		} else {
			this.ui.reasonCode.hide();
		}

		// IBECORP-3377
		if (this.keyContact && this.keyContact.values) {
			this.ui.keyContact.html(new KeyContactView({
				model: this.model,
				keyContact: this.keyContact,
			}).$el);
		} else {
			this.ui.keyContact.hide();
		}
	},
	
	clearTravellersCostCodesHandler() {
		if (this.travellersCostCodesHandler) {
			this.travellersCostCodesHandler.clear();
		}
	},

	remove() {
		this.clearTravellersCostCodesHandler();
		BaseView.prototype.remove.call(this);
	},

});
