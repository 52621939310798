module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


	_.each(reservation.services, function (s) {
		if (s == null) {
			s = {};
			return;
		}
		if	(s.offer == null) {
			s.offer = {};
		}
	});
	var MAX_NODES_IN_COLUMN = 5;

	var createdDate = new Time(order.created).format('DD.MM.YY');
	var travelSubject = reservation.travelSubject.uid;
	var hasRefundableService = _.some(reservation.services, function (el) {
		return (el.refundable === true);
	});
	var hasExchangeableService = _.some(reservation.services, function (el) {
			return (el.exchangeable === true);
	});

	var hasEditingService = hasRefundableService || hasExchangeableService;

	var cancellationHours = reservation.cancellationNoPenaltyHours;
	var freeCancellationText = L10N.get('transfers.cancellationWarning.firstPart') + cancellationHours.toString();
	if (cancellationHours > 5 && cancellationHours < 21) {
			freeCancellationText += L10N.get('transfers.cancellationWarning.greaterThen5');
	} else if (cancellationHours === 1 || cancellationHours.toString().match(/(^[0-9]{1,3})(1)/)) {
			freeCancellationText += L10N.get('transfers.cancellationWarning.oneHour');
	} else {
			freeCancellationText += L10N.get('transfers.cancellationWarning.lessThen5');
	}
	freeCancellationText += L10N.get('transfers.cancellationWarning.lastPart');

	var showFreeCancellationText = reservation.cancellationNoPenaltyHours && ['BOOKING', 'PAYED', 'ISSUED'].indexOf(reservation.status.uid) !== -1;

	var showFooterInfo =
        ['BOOKING', 'PAYED', 'ISSUED', 'INTENTION'].indexOf(reservation.status.uid) !== -1 ||
        hasRefundableService ||
        showFreeCancellationText ||
        reservation.isRefundBookingAllowed ||
        reservation.isCancelBookingAllowed ||
        (reservation.travelPolicyStatus && reservation.travelPolicyStatus.uid === "AUTHORIZATION_REQUESTED");

	function isBlurred(service) {
			return service.aclViewGranted ? '' : 'content-blur';
	}

	var isEditGrantedForAllServices = _.every(reservation.services, (p) => p.aclEditGranted);

;
__p += '\n\n<div>\n	<div class="b-order__container-header">\n		<div class="b-order__container-header-label b-order-label">\n			<i class="g-icon-service g-icon-service--type-' +
((__t = ( travelSubject.toLowerCase() )) == null ? '' : __t) +
'"></i>\n			';
 if (reservation.status.uid === 'ERROR') { ;
__p += '\n				<span class="b-order__info-error" data-toggle="tooltip" data-placement="bottom" data-html="true" data-original-title="<div class=\'b-car-place-tooltip\'><div class=\'tooltip__header\'>' +
((__t = ( L10N.get('settings.error') )) == null ? '' : __t) +
'</div><div class=\'tooltip__body\'>' +
((__t = ( L10N.get('settings.errorTechSupport') )) == null ? '' : __t) +
'</div>">' +
((__t = ( L10N.get('settings.error') )) == null ? '' : __t) +
'</span>\n			';
 } ;
__p += '\n			<div class="b-order-label__list">\n				';
 if (reservation.startAddress && reservation.endAddress) { ;
__p += '\n					<span class="b-order-label__item b-order-label__route" data-toggle="tooltip" data-placement="bottom" data-html="true" data-original-title="<div class=\'nowrap max-width-unset\'>' +
((__t = ( reservation.startAddress )) == null ? '' : __t) +
' - ' +
((__t = ( reservation.endAddress )) == null ? '' : __t) +
'</div>">\n						' +
((__t = ( reservation.startAddress )) == null ? '' : __t) +
' - ' +
((__t = ( reservation.endAddress )) == null ? '' : __t) +
'\n					</span>\n                    <span class="js-copy-content copy-content">' +
((__t = ( L10N.get('hotels.address') )) == null ? '' : __t) +
': ' +
((__t = ( reservation.startAddress )) == null ? '' : __t) +
' - ' +
((__t = ( reservation.endAddress )) == null ? '' : __t) +
'</span>\n				';
 } ;
__p += '\n\n				<span class="b-order-label__item b-order-label__create-date">\n					' +
((__t = ( createdDate )) == null ? '' : __t) +
'\n				</span>\n\n				';
 if (reservation.prn != null) { ;
__p += '\n					<span class="b-order-label__item  b-order-label__pnr">\n            ';
 if (reservation.showGdsAccountName) { ;
__p += '\n              <span class="b-order-label__item-extra">' +
((__t = ( reservation.gdsAccountName )) == null ? '' : __t) +
'</span>\n            ';
 } ;
__p += '\n						<span class="b-order-label__item-simple">' +
((__t = ( L10N.get('cabinet.orders.reservationPnr') )) == null ? '' : __t) +
':</span>\n						' +
((__t = ( reservation.prn )) == null ? '' : __t) +
'\n					</span>\n				';
 } ;
__p += '\n			</div>\n            ';
 if (reservation.incomplete) { ;
__p += '\n                <span class="missing-info-warning"\n                      data-toggle="tooltip"\n                      data-placement="bottom"\n                      data-html="true"\n                      data-original-title="<div>' +
((__t = ( L10N.get('cabinet.orders.incompleteReservationWarning') )) == null ? '' : __t) +
'</div>"\n                ></span>\n            ';
 } ;
__p += '\n		</div>\n		<div class="b-order__container-header-right">\n			<div class="b-order-price">\n				<span data-toggle="tooltip" data-placement="bottom" data-html="true" data-original-title="' +
((__t = ( _.escape(require('@/blocks/elements/b-transfer/b-transfer-order/b-ticket-pricing.ejs')({ price: reservation._price || reservation.price, services: reservation.services })) )) == null ? '' : __t) +
'">\n					' +
((__t = ( Formatter.formatMoney(reservation.price.total.amount, Formatter.getDecimal(reservation.price.total.amount)) )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(reservation.price.total.currencyCode) )) == null ? '' : __t) +
'\n				</span>\n			</div>\n            <span class="js-copy-content copy-content">' +
((__t = ( L10N.get('hotels.cost') )) == null ? '' : __t) +
': <span data-copy-content-from=".b-order-price > :first-child"></span></span>\n            <span class="js-copy-content copy-content"><span data-find-copy-in-tooltip data-copy-title-from=".b-order-price > :first-child"></span></span>\n			';
 if (!(reservation.status && reservation.status.uid === 'ERROR')) { ;
__p += '\n				';
 if (['INTENTION', 'BOOKING', 'AUTHORIZATION', 'PROPOSAL'].indexOf(reservation.status.uid) !== -1) { ;
__p += '\n				<span class="js-widget"  onclick=\'return new Widgets.Checkbox({bindingProperty: "selectedReservation.' + index + '.selected"})\'></span>\n				';
 } ;
__p += '\n			';
 } ;
__p += '\n		</div>\n	</div>\n	<div class="b-order__container-content">\n		<table class="b-block-table">\n			<tr class="b-block-table__row b-block-table__row-header">\n				';
 if (isEditGrantedForAllServices && hasEditingService === true) { ;
__p += '\n				<th class="b-block-table__cell b-block-table__cell-type-checkbox">\n					<span class="js-widget"  onclick=\'return new Widgets.Checkbox({bindingProperty: "selectedServices.' + index + '.allServices"})\'></span>\n				</th>\n				';
 } else { ;
__p += '\n					<th class="b-block-table__cell b-block-table__cell-empty"></th>\n				';
 } ;
__p += '\n				<th class="b-block-table__cell">' +
((__t = ( L10N.get('cabinet.orders.ticketNumber') )) == null ? '' : __t) +
'</th>\n				<th class="b-block-table__cell">' +
((__t = ( L10N.get('cabinet.orders.fullName') )) == null ? '' : __t) +
'</th>\n				<th class="b-block-table__cell">' +
((__t = ( L10N.get('cabinet.orders.passengersAmount') )) == null ? '' : __t) +
'</th>\n				<th class="b-block-table__cell b-block-table__cell--width-auto">\n                    ' +
((__t = ( L10N.get('cabinet.orders.hotelStatus') )) == null ? '' : __t) +
'\n                </th>\n				<th class="b-block-table__cell"></th>\n			</tr>\n            ';
 if (isEditGrantedForAllServices && hasEditingService === true) { ;
__p += '\n                <tr class="b-block-table__row desktop-hide">\n                    <td class="b-block-table__cell b-block-table__cell-type-checkbox b-block-table__cell--checkobx-with-caption">\n                        <span class="js-widget"  onclick=\'return new Widgets.Checkbox({bindingProperty: "selectedServices.' + index + '.allServices"})\'></span>\n                        <span>' +
((__t = ( L10N.get('cabinet.orders.selectAll') )) == null ? '' : __t) +
'</span>\n                    </td>\n                </tr>\n            ';
 } ;
__p += '\n			';
 _.each(reservation.services, function(p, i) {
				var passengers = p.travellers;
				var aclEditGranted = p.aclEditGranted;
			;
__p += '\n			<tr class="b-block-table__row" data-service-uid="' +
((__t = ( p.uid )) == null ? '' : __t) +
'" data-service-status="' +
((__t = ( p.status && p.status.uid )) == null ? '' : __t) +
'">\n				';
 if (hasEditingService === true && aclEditGranted) { ;
__p += '\n				<td class="b-block-table__cell b-block-table__cell-type-checkbox b-block-table__cell--checkobx-with-caption">\n					<span class="js-widget"  onclick=\'return new Widgets.Checkbox({bindingProperty: "selectedServices.' + index + '.' + p.uid + '.selected"})\'></span>\n                    <span class="desktop-hide">' +
((__t = ( L10N.get('cabinet.orders.selectService') )) == null ? '' : __t) +
'</span>\n				</td>\n				';
 } else { ;
__p += '\n					<td class="b-block-table__cell b-block-table__cell-empty"></td>\n				';
 } ;
__p += '\n\n				<td class="b-block-table__cell b-block-table__cell-ticket-number ' +
((__t = ( isBlurred(p) )) == null ? '' : __t) +
'">\n                    <span class="b-block-table__cell-header-mobile desktop-hide ' +
((__t = ( isBlurred(p) )) == null ? '' : __t) +
'">' +
((__t = ( L10N.get('cabinet.orders.ticketNumber') )) == null ? '' : __t) +
'</span>\n                    <span class="b-order__info-ticket-number">\n                    	' +
((__t = ( (p.ticketNumber != null) ? p.ticketNumber : '一' )) == null ? '' : __t) +
'\n                    </span>\n                    <span class="js-copy-content copy-content">' +
((__t = ( i > 0 ? '\n' : '')) == null ? '' : __t) +
'' +
((__t = ( L10N.get('cabinet.orders.ticketNumber') )) == null ? '' : __t) +
': ' +
((__t = ( (p.ticketNumber != null) ? p.ticketNumber : '一' )) == null ? '' : __t) +
' (' +
((__t = ( p.status && p.status.caption )) == null ? '' : __t) +
')</span>\n				</td>\n				<td class="b-block-table__cell b-block-table__cell-fullname ' +
((__t = ( isBlurred(p) )) == null ? '' : __t) +
'">\n					';
 const passengersFullName = _.map(passengers.slice(0, MAX_NODES_IN_COLUMN), function(el) {
						return '<div class="overflow-field">' + (el && el.fullName.toUpperCase() || '-') + '</div>';
					}).join('') ;
__p += '\n                    ' +
((__t = ( passengersFullName )) == null ? '' : __t) +
'\n					';
 if (passengers.length > MAX_NODES_IN_COLUMN) { ;
__p += '\n						<span\n								class="action-link more"\n								data-toggle="tooltip"\n								data-placement="bottom"\n								data-html="true"\n								data-original-title="<div>' +
((__t = ( passengers.slice(MAX_NODES_IN_COLUMN).join(', ') )) == null ? '' : __t) +
'</div>">\n								' +
((__t = ( L10N.get('Common.more') )) == null ? '' : __t) +
'\n								' +
((__t = ( passengers.length - MAX_NODES_IN_COLUMN )) == null ? '' : __t) +
'\n						</span>\n					';
 } ;
__p += '\n                    <span class="js-copy-content copy-content" data-replace-newline=", "><strong>' +
((__t = ( L10N.get('cabinet.orders.fullName') )) == null ? '' : __t) +
'</strong>: ' +
((__t = ( passengersFullName )) == null ? '' : __t) +
' ';
 if (passengers.length > MAX_NODES_IN_COLUMN) { ;
__p += ' ' +
((__t = ( passengers.slice(MAX_NODES_IN_COLUMN).join(', ') )) == null ? '' : __t) +
' ';
 } ;
__p += '</span>\n				</td>\n				<td class="b-block-table__cell  ' +
((__t = ( isBlurred(p) )) == null ? '' : __t) +
'">\n                    <div class="b-block-table__cell-content-container">\n                        <span class="b-block-table__cell-header-mobile desktop-hide">' +
((__t = ( L10N.get('cabinet.orders.passengersAmount') )) == null ? '' : __t) +
'</span>\n                        <span>' +
((__t = ( p.passengersCount )) == null ? '' : __t) +
' <span class="b-block-table__cell-header-mobile">' +
((__t = ( L10N.get("cabinet.orders.pers") )) == null ? '' : __t) +
'</span></span>\n                    </div>\n                    <span class="js-copy-content copy-content" data-replace-newline=", "><strong>' +
((__t = ( L10N.get('cabinet.orders.passengersAmount') )) == null ? '' : __t) +
'</strong>: ' +
((__t = ( p.passengersCount )) == null ? '' : __t) +
' ' +
((__t = ( L10N.get("cabinet.orders.pers") )) == null ? '' : __t) +
'</span>\n				</td>\n				<td class="b-block-table__cell b-block-table__cell-status">\n					<div class="b-order__info-status-container">\n                        <span class="b-order__info-status b-order__info-status--' +
((__t = ( p.status && p.status.uid )) == null ? '' : __t) +
'">\n                            ' +
((__t = ( p.status && p.status.caption )) == null ? '' : __t) +
'\n                        </span>\n                        ';
 if (useAuthSteps) { ;
__p += '\n                            <button\n                                type="button"\n                                class="b-order__show-detailed js-open-auth-steps-modal"\n                                data-reservation-uid="' +
((__t = ( reservation.uid )) == null ? '' : __t) +
'"\n                                data-service-uid="' +
((__t = ( p.uid )) == null ? '' : __t) +
'"\n                            >\n                                ' +
((__t = ( L10N.get('cabinet.orders.showDetails') )) == null ? '' : __t) +
'\n                            </button>\n                        ';
 } ;
__p += '\n                    </div>\n				</td>\n                <td class="b-block-table__cell b-block-table__cell-travel-policy">\n                    <div class="b-block-table__cell-content-container order-reservation__travel-policy-container b-order-reservation__travel-policy">\n                        ';
 if (p.travelPolicyCompliance && !_.isEmpty(p.travelPolicyCompliance.rulesApplied)) { ;
__p += '\n                          ' +
((__t = ( require('@/blocks/elements/b-hotel/b-hotel-order/b-hotel-info/b-travel-policy.ejs')({
                            travelPolicyCompliance: p.travelPolicyCompliance,
                          }) )) == null ? '' : __t) +
'\n                        ';
 } ;
__p += '\n                        ';
 if (p.isTripartiteDiscount) { ;
__p += '\n                            <span class="b-order-reservation__travel-policy--3D g-icon-features-3D-contract" data-toggle="tooltip" data-placement="bottom" data-title="' +
((__t = ( L10N.get('filters.tripartite'))) == null ? '' : __t) +
'"></span>\n                        ';
 } ;
__p += '\n                    </div>\n                </td>\n			</tr>\n			';
 }); ;
__p += '\n			';
 if (showFooterInfo) { ;
__p += '\n				<tr class="b-block-table__row b-order__info-footer">\n					<td class="b-block-table__cell ' +
((__t = ( (hasEditingService === true) ? 'b-block-table__cell-type-checkbox' : 'b-block-table__cell-empty' )) == null ? '' : __t) +
'"></td>\n					<td colspan="3" class="b-block-table__cell">\n						<div class="b-order__info-footer-container">\n							<div class="b-order__info-footer-controls">\n                                ';
 if (reservation.isCancelBookingAllowed && STATE.getLoggedInUser() != null) { ;
__p += '\n                                    <span class="b-order__info-footer-button b-payment__cancel mobile-hide">' +
((__t = ( L10N.get('cabinet.orders.cancel') )) == null ? '' : __t) +
'</span>\n                                ';
 } ;
__p += '\n                                ';
 if (hasRefundableService && reservation.isRefundBookingAllowed && STATE.getLoggedInUser() != null) { ;
__p += '\n                                    <span class="b-order__info-footer-button b-refund-services mobile-hide">' +
((__t = ( L10N.get('cabinet.orders.refund') )) == null ? '' : __t) +
'</span>\n                                ';
 } ;
__p += '\n							</div>\n                            <div class="b-order__transfer-notifications">\n                                ';
 if (showFreeCancellationText) { ;
__p += '\n                                    <div class="b-notification__cancellation-policy">\n                                        <div>' +
((__t = ( freeCancellationText )) == null ? '' : __t) +
'</div>\n                                    </div>\n                                    <span class="js-copy-content copy-content">' +
((__t = ( freeCancellationText )) == null ? '' : __t) +
'</span>\n                                ';
 } ;
__p += '\n                            </div>\n						</div>\n					</td>\n					<td colspan="1" class="b-block-table__cell b-order__info-footer-right">\n						';
 if (['PAYED', 'ISSUED'].indexOf(reservation.status.uid) !== -1) { ;
__p += '\n							<span class="b-order__info-footer-item b-route-receipt-link mobile-hide">\n								<span class="action-link">' +
((__t = ( L10N.get('cabinet.orders.routeDocument') )) == null ? '' : __t) +
'</span>\n								<i class="g-icon-download"></i>\n							</span>\n						';
 } else if (reservation.approveAuthorizationAllowed && reservation.refuseAuthorizationAllowed) { ;
__p += '\n                            <span class="mobile-hide">\n								<button type="button" class="btn btn-danger b-order__refuse_auth">\n									' +
((__t = ( L10N.get('cabinet.orders.refuse') )) == null ? '' : __t) +
'\n								</button>\n							</span>\n                            <span class="mobile-hide">\n								<button type="button" class="btn btn-green b-order__approve_auth">\n									' +
((__t = ( L10N.get('cabinet.orders.confirm') )) == null ? '' : __t) +
'\n								</button>\n							</span> \n						';
 } else if (['TRANSFER'].indexOf(reservation.travelSubject.uid) !== -1 && reservation.hasOwnProperty('approvalNeeded') && reservation.approvalNeeded) { ;
__p += '\n                            <span class="action-link mobile-hide">\n								<button type="button" class="btn btn-dark b-order__intention-approval" data-type="' +
((__t = ( reservation.travelSubject.uid )) == null ? '' : __t) +
'">\n									' +
((__t = ( L10N.get('cabinet.orders.doBooking') )) == null ? '' : __t) +
'\n								</button>\n							</span>\n                        ';
 } ;
__p += '\n					</td>\n                    <td class="b-block-table__cell mobile-cell">\n                        ';
 if (reservation.isCancelBookingAllowed && STATE.getLoggedInUser() != null) { ;
__p += '\n                            <span class="b-order__info-footer-button b-payment__cancel">' +
((__t = ( L10N.get('cabinet.orders.cancel') )) == null ? '' : __t) +
'</span>\n                        ';
 } ;
__p += '\n						';
 if (reservation.approveAuthorizationAllowed && reservation.refuseAuthorizationAllowed) { ;
__p += ' \n                            <span class="b-order__info-footer-button">\n								<button type="button" class="btn btn-danger b-order__refuse_auth">\n									' +
((__t = ( L10N.get('cabinet.orders.refuse') )) == null ? '' : __t) +
'\n								</button>\n							</span>\n                            <span class="b-order__info-footer-button">\n								<button type="button" class="btn btn-green b-order__approve_auth">\n									' +
((__t = ( L10N.get('cabinet.orders.confirm') )) == null ? '' : __t) +
'\n								</button>\n							</span>\n                        ';
 } ;
__p += '\n                        ';
 if (['TRANSFER'].indexOf(reservation.travelSubject.uid) !== -1 && reservation.hasOwnProperty('approvalNeeded') && reservation.approvalNeeded) { ;
__p += '\n                        	<button type="button" class="btn btn-dark b-order__intention-approval" data-type="' +
((__t = ( reservation.travelSubject.uid )) == null ? '' : __t) +
'">\n                        		' +
((__t = ( L10N.get('cabinet.orders.toBooking') )) == null ? '' : __t) +
'\n                        	</button>\n                        ';
 } ;
__p += '\n                        <div class="b-order__info-footer-controls-mobile js-show-mobile-controls desktop-hide expand-only"></div>\n                        <div class="b-order__info-footer-controls-popup js-mobile-controls desktop-hide">\n                            <div class="b-order__info-footer-controls-mobile--mobile-btn js-footer-controls-btn" data-target-class=".b-route-receipt-link">\n                                ' +
((__t = ( L10N.get('cabinet.orders.routeDocument') )) == null ? '' : __t) +
'\n                            </div>\n                            <div class="b-order__info-footer-controls-mobile--mobile-btn js-footer-controls-btn" data-target-class=".b-refund-services">\n                                ' +
((__t = ( L10N.get('cabinet.orders.refund') )) == null ? '' : __t) +
'\n                            </div>\n                            <div class="b-order__info-footer-controls-mobile--mobile-btn js-footer-controls-btn" data-target-class=".b-expand">\n                                ' +
((__t = ( L10N.get('loginForm.rulesMore') )) == null ? '' : __t) +
'\n                            </div>\n                        </div>\n                    </td>\n					<td class="b-block-table__cell b-block-table__cell-empty"></td>\n				</tr>\n			';
 } else { ;
__p += '\n                <tr class="b-block-table__row b-order__info-footer">\n                    <td class="b-block-table__cell mobile-cell">\n                        <div class="b-order__info-footer-controls-mobile js-show-mobile-controls desktop-hide expand-only"></div>\n                        <div class="b-order__info-footer-controls-popup js-mobile-controls desktop-hide">\n                            <div class="b-order__info-footer-controls-mobile--mobile-btn js-footer-controls-btn" data-target-class=".b-route-receipt-link">\n                                ' +
((__t = ( L10N.get('cabinet.orders.routeDocument') )) == null ? '' : __t) +
'\n                            </div>\n                            <div class="b-order__info-footer-controls-mobile--mobile-btn js-footer-controls-btn" data-target-class=".b-refund-services">\n                                ' +
((__t = ( L10N.get('cabinet.orders.refund') )) == null ? '' : __t) +
'\n                            </div>\n                            <div class="b-order__info-footer-controls-mobile--mobile-btn js-footer-controls-btn" data-target-class=".b-expand">\n                                ' +
((__t = ( L10N.get('loginForm.rulesMore') )) == null ? '' : __t) +
'\n                            </div>\n                        </div>\n                    </td>\n                </tr>\n            ';
 } ;
__p += '\n		</table>\n		<div class="b-order__info-expand b-expand collapsed"></div>\n	</div>\n	<div class="b-order__container-expand">\n		<div class="b-order__container-expand-inner"></div>\n		<div class="b-order__info-expand b-expand"></div>\n	</div>\n</div>\n';

}
return __p
}