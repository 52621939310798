import { ReactNode, useCallback, useContext, useEffect, useReducer, useRef } from "react";
import { createPortal } from "react-dom";
import Widgets from "../../../../js/widgets/widgets";
import { ReactAppGlobalContext } from "../../../../js/utils/react-utils";
import React from "react";

interface ReactPopupProps {
	opened: boolean;
	content: ReactNode;
}

const ReactPopup: React.FC<ReactPopupProps> = (props) => {
	const { opened, content } = props;

	const { L10N } = useContext(ReactAppGlobalContext);

  const [ignored, forceUpdate] = useReducer(x => x + 1, 0)

	const popupRef = useRef<{
		show: () => void;
		hide: () => void;
		remove: () => void;
		ui: any;
	} | null>(null);

	const contentHolderRef = useRef<any>(null);

	const initPopupRef = useCallback(() => {
		if (!popupRef.current) {
			popupRef.current = new Widgets.Popup({
				content: "<div class='js-react-content-holder'></div>",
				closeOnlyOnAction: true,
				type: "info",
				actions: [
					{
						label: `<i class="g-icon g-icon-reload"></i> ${L10N.get('Common.close')}`,
						action: () => {
							if (popupRef.current) {
								popupRef.current.remove();
							}
						},
					},
				],
				classes: "b-reload-popup",
			});
			if (popupRef.current) {
				const contentHolder = popupRef.current?.ui?.content
					?.get(0)
					?.querySelector(".js-react-content-holder");
				contentHolderRef.current = contentHolder;
			}
		}
	}, []);

	useEffect(() => {
		if (opened) {
			if (!popupRef.current) {
				initPopupRef();
			}
			if (popupRef.current) {
				popupRef.current.show();
        forceUpdate();
			}
		} else {
			if (popupRef.current) {
				popupRef.current.remove();
        popupRef.current = null;
        contentHolderRef.current = null;
			}
		}
	}, [opened]);

	return (
		<>
			{popupRef.current && contentHolderRef.current && (
				<>{createPortal(content, contentHolderRef.current)}</>
			)}
		</>
	);
};

export default ReactPopup;
