import "./style.less";
import sanitizeHtml from "sanitize-html";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { NewsItem } from "../helpers/interfaces";
import moment from "moment";
import {
	fetchMarkRead,
	fetchNewsItemDetails,
} from "../NewsCard/helpers/services";
import { NewItemDetails } from "../NewsCard/helpers/interfaces";
import Button from "../../../components/form/Button/Button";
import { ReactAppGlobalContext } from "../../../../js/utils/react-utils";

interface ImportantNewsProps {
	news: NewsItem[];
	close: () => void;
}

const ImportantNews: React.FC<ImportantNewsProps> = (props) => {
	const { news, close } = props;

  const { L10N } = useContext(ReactAppGlobalContext);

	const [details, setDetails] = useState<NewItemDetails>();
	const [currentActive, setCurrentActive] = useState<number | null>(null);

	const currentNewsItem = useMemo(
		() => (currentActive != null ? news[currentActive] : undefined),
		[currentActive]
	);

	const fetchDetails = useCallback(async (uid: string) => {
		const newsItemDetails = await fetchNewsItemDetails(uid);
		setDetails(newsItemDetails);
	}, []);

	const handleReadClick = useCallback(async () => {
		if (currentNewsItem) {
			await fetchMarkRead(currentNewsItem.uid);
			if (currentActive !== null && news[currentActive + 1]) {
				setCurrentActive(currentActive + 1);
			} else {
				setCurrentActive(null);
				close && close();
			}
		}
	}, [currentNewsItem, currentActive, news, close]);

	useEffect(() => {
		if (news.length > 0) {
			setCurrentActive(0);
		} else {
			setCurrentActive(null);
		}
	}, [news]);

	useEffect(() => {
		if (currentNewsItem) {
			fetchDetails(currentNewsItem.uid);
		}
	}, [currentNewsItem]);

	return (
		<div className="r-important-news">
			{currentNewsItem && (
				<>
					<div className="r-important-news-item__title">
						{currentNewsItem.title}
					</div>
					<div className="r-important-news-item__date">
						{(currentNewsItem.created &&
							moment(currentNewsItem.created).format("DD.MM.YYYY, HH:mm")) ||
							""}
					</div>
					<div
						className="r-important-news-item__text"
						dangerouslySetInnerHTML={{
							__html: sanitizeHtml(details?.content || ""),
						}}
					/>
					<Button style={{ alignSelf: "center" }} onClick={handleReadClick}>
						{L10N.get('news.importantNews.markAsRead')}
					</Button>
				</>
			)}
		</div>
	);
};

export default ImportantNews;
