import "./style.less";
import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react";
import { ReactAppGlobalContext } from "../../../../utils/react-utils";
import { useNoticesStore } from "../../../../../react/store/notices";
import { fetchNoticesSummary } from "./helpers/services";
import ReactPopup from "../../../../../react/components/form/ReactPopup/ReactPopup";
import ImportantNews from "../../../../../react/pages/news/ImportantNews/ImportantNews";
import { NewsItem } from "../../../../../react/pages/news/helpers/interfaces";

const NewsBlock: React.FC<unknown> = () => {
	const { STATE, L10N } = useContext(ReactAppGlobalContext);

	const { updateSummary, summary } = useNoticesStore();

	const loadSummary = useCallback(async () => {
		const newSummary = await fetchNoticesSummary();
		updateSummary(newSummary);
	}, []);

	useEffect(() => {
		loadSummary();
	}, []);

	const [showImportantNewsPopup, setShowImportantNewsPopup] =
		useState<boolean | null>(null);
	const popupNews = useMemo(() => {
		const { unreadImportant = [] } = summary;
		return unreadImportant.filter((news: NewsItem) => news.important);
	}, [summary]);

	useEffect(() => {
    // show only once
		if (showImportantNewsPopup === null && popupNews.length > 0) {
			setShowImportantNewsPopup(true);
		}
	}, [popupNews, showImportantNewsPopup]);

  useEffect(() => {
    // reload summary after modal close
    loadSummary();
  }, [showImportantNewsPopup]);

	return (
		<>
			<a href={`#/${STATE.ROUTES.NEWS}`}>
				<div className="b-newsblock">
					{L10N.get('news.newsBlock.title')}:{" "}
					{summary && (
						<>
							{summary.unreadImportantCount}/{summary.unreadCount}
						</>
					)}
				</div>
			</a>
			<ReactPopup
				opened={showImportantNewsPopup === true}
				content={
					<ImportantNews
						close={() => setShowImportantNewsPopup(false)}
						news={popupNews}
					/>
				}
			/>
		</>
	);
};

export default NewsBlock;
