// eslint-disable-next-line banned-modules
'use strict';

import $ from 'jquery';
import Backbone from 'backbone';
import SearchModel from '@/pages/search/model';
import axios from 'axios';
import cookies from 'js-cookie';
import LZString from 'lz-string';

const getQueryVariable = (variable) => {
	const query = window.location.search.substring(1);
	const vars = query.split('&');
	for (let i = 0; i < vars.length; i++) {
		const pair = vars[i].split('=');
		if (decodeURIComponent(pair[0]) === variable) {
			return decodeURIComponent(pair[1]);
		}
	}
	return null;
};

const getQueryLocale = () => {
	return getQueryVariable('locale');
};

const StateModel = Backbone.Model.extend({

	defaults: {
		versionControl: {
			timerId: null,
			version: null,
		},
		searchModel: null,
		loading: false,
		pageView: null,
		loginView: null,
		siteSettings: null,
		lang: getQueryLocale() || 'ru',
		savedXhr: {},
		isTablePage: false,
	},

	ROUTES: {
		INDEX: '',
		AVIA_TICKETS: 'avia/tickets',
		AVIA_PASSENGERS: 'avia/passengers',
		AVIA_PAYMENT: 'avia/payment',
		AVIA_BRANDED_FARES: 'avia/brands/tickets',

		RAILWAYS_TICKETS: 'trains/tickets/route/1',
		RAILWAYS_TICKETS_1: 'trains/tickets/route/1',
		RAILWAYS_TICKETS_2: 'trains/tickets/route/2',
		RAILWAYS_TICKETS_PRICING: 'rail/pricing/route/1',
		RAILWAYS_TICKETS_PRICING_2: 'rail/pricing/route/2',
		RAILWAYS_PASSENGERS: 'trains/passengers',
		RAILWAYS_PAYMENT: 'trains/payment',
		RAILWAYS_PREVIEW: 'trains/preview',

		HOTELS_OFFERS: 'hotels/offers',
		HOTELS_PASSENGERS: 'hotels/travellers',
		HOTELS_PAYMENT: 'hotels/payment',
		HOTELS_PREVIEW: 'hotels/preview',

		TRANSFERS_OFFERS: 'transfers/offers',
		TRANSFERS_PASSENGERS: 'transfers/travellers',
		TRANSFERS_PAYMENT: 'transfers/payment',

		MAIN_SEARCH: 'main_search',
		PAYMENTS: 'payment',
		NEWS: 'news',

		CABINET_ORDERS: 'cabinet/orders',
		CABINET_ORDER: 'cabinet/order',
		CABINET_PASSENGERS: 'cabinet/passengers',
		CABINET_PASSENGER: 'cabinet/passenger',
		CABINET_PROFILE: 'cabinet/profile',
		CABINET_EMPLOYEES: 'cabinet/employees',
		CABINET_EMPLOYEE: 'cabinet/employee',
	},

	FILLING_TYPE: {
		FIRST_TRAVELLER: 'FIRST_TRAVELLER',
		AUTHORIZED_USER: 'AUTHORIZED_USER',
	},

	QUERY_DELAY: 600,
	IS_403_HANDLED: false,

	initialize() {
		const locale = STORE.get('locale');
		if (this.getLanguage() !== locale) {
			STORE.clear();
			STORE.set('locale', this.getLanguage());
		}
	},

	showLogin() {
		// eslint-disable-next-line no-use-before-define
		if (STATE.loginView != null) STATE.loginView.showLoginView();
	},

	hideLogin() {
		// eslint-disable-next-line no-use-before-define
		if (STATE.loginView != null) STATE.loginView.hideLoginView();
	},

	hideAdditionalOrder() {
		// eslint-disable-next-line no-use-before-define
		if (STATE.layout.header != null) {
			// eslint-disable-next-line no-use-before-define
			STATE.layout.header.setAdditionalOrder(null);
		}
	},

	rearrangeHeaderInfoToMenu() {
		// eslint-disable-next-line no-use-before-define
		if (STATE.layout.header == null) return;
		// eslint-disable-next-line no-use-before-define
		const header = STATE.layout.header.$el;
		const profile = header.find('.b-header__info-account');
		const additionalClient = header.find('.b-header__additional-client');
		const additionalOrder = header.find('.b-header__additional-order');
		const infoCurrency = header.find('.b-header__info-currency');

		const cabinetMenu = $('.b-cabinet');
		cabinetMenu.find('.b-cabinet__profile').html(profile.html());
		if (profile.is(':empty') || profile.children().is(':empty')) cabinetMenu.find('.b-cabinet__profile').hide();
		cabinetMenu.find('.b-cabinet__additional-order').find('.js-container').html(additionalOrder.html());
		if (additionalOrder.is(':empty')) cabinetMenu.find('.b-cabinet__additional-order').hide();
		cabinetMenu.find('.b-cabinet__additional-client').find('.js-container').html(additionalClient.html());
		if (additionalClient.is(':empty')) cabinetMenu.find('.b-cabinet__additional-client').hide();
		cabinetMenu.find('.b-cabinet__info-currency').html(infoCurrency.html());
		if (!_.size(infoCurrency) || infoCurrency.is(':empty')) cabinetMenu.find('.b-cabinet__info-currency').hide();
	},

	getRootUrl() {
		return `${window.location.origin}${window.location.pathname ? window.location.pathname : ''}${window.location.search}`;
	},

	getLanguage() {
		if (getQueryLocale() && getQueryLocale() !== this.get('lang')) {
			this.setLanguage(getQueryLocale());
		}
		return this.get('lang');
	},

	setLanguage(value) {
		STORE.clear();
		STORE.set('locale', value);
		return this.set('lang', value);
	},

	saveXhr(request, response) {
		STORE.set(JSON.stringify(request), !response ? 'PENDING' : LZString.compressToUTF16(JSON.stringify(response)), 6000);
	},

	getSavedXhr(request) {
		const result = STORE.get(JSON.stringify(request));
		if (!result) return null;
		return result === 'PENDING' ? result : JSON.parse(LZString.decompressFromUTF16(result));
	},

	getSearchModel() {
		if (!this.get('searchModel')) {
			const settings = this.getSettings();
			const b2cSettings = {
				isB2C: this.checkSiteType('B2C'),
				client: settings && settings.corporateSettings && settings.corporateSettings.predefinedClientReference,
			};
			if (STORE.get(this.ROUTES.MAIN_SEARCH)) {
				this.set('searchModel', new SearchModel(STORE.get(this.ROUTES.MAIN_SEARCH), {settings, b2cSettings}));
			} else {
				this.set('searchModel', new SearchModel({}, {settings, b2cSettings}));
			}
			this.get('searchModel')
				.set('changed', false);
		}
		return this.get('searchModel');
	},

	getSearchParametrs() {
		if (STORE.get(this.ROUTES.MAIN_SEARCH) == null) {
			STORE.set(this.ROUTES.MAIN_SEARCH, this.getSearchModel().attributes, 14400);
		}

		if (this.getIsFromHash()) {
			STORE.get(this.ROUTES.MAIN_SEARCH, this.getSearchModel().attributes, 14400);
		}

		const parameters = STORE.get(this.ROUTES.MAIN_SEARCH);
		const that = this;
		Object.setPrototypeOf(parameters, {
			toJSON: function toJSON() {
				const fakeModel = _.omit(this, 'toJSON');
				const settings = that.getSettings();
				return new SearchModel(fakeModel, {
					settings,
					b2cSettings: {
						isB2C: that.checkSiteType('B2C'),
						client: settings && settings.corporateSettings && settings.corporateSettings.predefinedClientReference,
					},
				}).toJSON();
			},
		});

		return parameters;
	},

	showLoader() {
		if (!this.loaderDelayTimer) {
			this.set('loading', true);
		}
	},

	hideLoader() {
		this.set('loading', false);
	},

	hideMenu() {
		$('.l-layout').removeClass('show-menu');
	},

	hideOfferPreview() {
		$('.l-layout').removeClass(`show-offer-preview`);
	},

	getPageView() {
		return this.get('pageView');
	},

	getPageViewRelatedViews() {
		return this.get('pageViewRelatedViews');
	},

	setPageViewRelatedViews(value) {
		this.set('pageViewRelatedViews', value);
	},

	getPageModel() {
		return this.get('pageView').getPageModel();
	},

	setPageView(pageView) {
		this.set('pageView', pageView);
	},

	setAxiosUrl(url) {
		this.set('axiosUrl', url);
	},

	getAxiosUrl() {
		return this.get('axiosUrl');
	},

	getParsedHash(hash, attrs) {
		const hashChunk = hash.split('!');

		hashChunk.forEach(item => {
			const chunk = item.split('|');

			const getTimeWindow = (el, obj) => {
				if (!el) return;
				if (el.indexOf('TWS') > -1) {
					obj.TWS = el.split('TWS')[1];
				}
				if (el.indexOf('TWE') > -1) {
					obj.TWE = el.split('TWE')[1];
				}
			};

			switch (chunk[0]) {
				case 'R':
					// eslint-disable-next-line no-case-declarations
					const timeWindow = {
						TWS: null,
						TWE: null,
					};
					_.each([chunk[4], chunk[5]], (el) => getTimeWindow(el, timeWindow));
					attrs.routes.push({
						departure: chunk[1],
						arrival: chunk[2],
						date: chunk[3],
						departureTimeWindowStart: timeWindow.TWS || undefined,
						departureTimeWindowEnd: timeWindow.TWE || undefined,
					});
					break;
				case 'CL':
					attrs.classOfService = chunk[1];
					break;
				case 'C':
					attrs.client = {uid: chunk[1], caption: chunk[1]};
					break;
				case 'CH':
					attrs.changed = chunk[1] === 'true';
					break;
				case 'RT':
					attrs.routeType = chunk[1];
					break;
				case 'PA':
					attrs.preferredAirlines = chunk.length > 1 ? _.map(chunk.slice(1, chunk.length), (el) => ({uid: el, caption: el})) : [];
					break;
				case 'CA':
					attrs.childsAge = chunk.length > 1 ? chunk.slice(1, chunk.length) : undefined;
					break;
				case 'PT':
					// eslint-disable-next-line no-case-declarations
					const keyValuePairs = chunk[1].split('$');
					_.each(keyValuePairs, (el) => {
						const [k, val] = el.split(':');
						attrs.passengersTypes[k] = parseInt(val);
					});
					break;
				case 'RM':
					// eslint-disable-next-line no-case-declarations
					const rooms = chunk[1].split('_');
					_.each(rooms, (el, i) => {
						const params = el.split('-');
						if (!attrs.rooms[i]) attrs.rooms[i] = {travellersInfo: {}, childrenAge: []};

						const travellersInfo = params[1].split('$');
						_.each(travellersInfo, (v) => {
							const [k, val] = v.split(':');
							attrs.rooms[i].travellersInfo[k] = parseInt(val);
						});

						if (params[2]) {
							const childrenAge = params[3].split('$');
							attrs.rooms[i].childrenAge = _.map(childrenAge, (n) => parseInt(n));
						}
						if (_.isEmpty(attrs.rooms[i].childrenAge)) delete attrs.rooms[i].childrenAge;
					});
					break;
				case 'D':
					attrs.direct = chunk[1] === 'true';
					break;
				case 'CT':
					attrs.cityCode = chunk[1];
					break;
				case 'CID':
					attrs.checkInDate = chunk[1];
					break;
				case 'COD':
					attrs.checkOutDate = chunk[1];
					break;
				case 'S':
					attrs.searchType = chunk[1];
					break;
				case 'SP':
					_.each(chunk[1].split(':'), (el, i) => {
						if (i === 0) attrs.startPlace.longitude = el;
						if (i === 1) attrs.startPlace.latitude = el;
					});
					break;
				case 'EP':
					_.each(chunk[1].split(':'), (el, i) => {
						if (i === 0) attrs.endPlace.longitude = el;
						if (i === 1) attrs.endPlace.latitude = el;
					});
					break;
				case 'SA':
					_.each(chunk[1].split('$'), (el) => {
						const [longitude, latitude] = el.split(':');
						attrs.subAddresses.push({longitude, latitude});
					});
					break;
				case 'DT':
					attrs.date = chunk[1];
					break;
				case 'BDT':
					attrs.backDate = chunk[1];
					break;
				case 'T':
					attrs.transferType = {uid:chunk[1], caption:chunk[1]};
					break;
				default:
					break;
			}
		});
	},

	checkHash(hash, tr = 'AVIA') {
		const hashInStore = STORE.get(`${tr}Hash`);
		if (hashInStore !== hash) {
			switch (tr) {
				case 'AVIA':
					STORE.remove(`formState:avia:filter`);
					this.clearLocalStorageForRoute([
						this.ROUTES.AVIA_TICKETS,
						this.ROUTES.AVIA_PASSENGERS,
						this.ROUTES.AVIA_PAYMENT,
					]);
					break;
				case 'HOTEL':
					STORE.remove(`formState:hotels:filter`);
					this.clearLocalStorageForRoute([
						this.ROUTES.HOTELS_OFFERS,
						this.ROUTES.HOTELS_PASSENGERS,
						this.ROUTES.HOTELS_PAYMENT,
						this.ROUTES.HOTELS_PREVIEW,
					]);
					break;
				case 'RAIL':
					STORE.remove(`formState:trains:filter`);
					this.clearLocalStorageForRoute([
						this.ROUTES.RAILWAYS_TICKETS_1,
						this.ROUTES.RAILWAYS_TICKETS_2,
						this.ROUTES.RAILWAYS_TICKETS_PRICING,
						this.ROUTES.RAILWAYS_TICKETS_PRICING_2,
						this.ROUTES.RAILWAYS_PASSENGERS,
						this.ROUTES.RAILWAYS_PREVIEW,
						this.ROUTES.RAILWAYS_PAYMENT,
					]);
					break;
				case 'TRANSFER':
					STORE.remove(`formState:transfers:filter`);
					this.clearLocalStorageForRoute([
						this.ROUTES.TRANSFERS_OFFERS,
						this.ROUTES.TRANSFERS_PASSENGERS,
						this.ROUTES.TRANSFERS_PAYMENT,
					]);
					break;
				default:
					break;
			}
		}
		STORE.set(`${tr}Hash`, hash);
	},

	async getLocations(uid, searchType, limit) {
		const result = await axios.post('midoffice/ibecorp-b2b/autocomplete/getLocations', {
			parameters: {
				pattern: uid,
				searchType,
				limit,
			},
		});
		let location = result.data.result.locations.find(item => {
			if (item.iataCode) {
				return item.uid === uid && item.iataCode;
			} else {
				return item.uid === uid;
			}
		});
		if (!location) {
			location = result.data.result.locations[0];
			location.uid = location.code;
			delete location.code;
		}
		return location;
	},

	getLocationSearch(locale) {
		if (window.location.search === '' || window.location.search == null || window.location.search === '?') {
			return `?locale=${locale}`;
		}
		let res = '';
		const localeIndex = window.location.search.indexOf('locale');
		const parts = window.location.search.substring(1).split('&');
		let changed = false;
		if (localeIndex < 0 && locale) {
			parts.unshift(`locale=${locale}`);
			changed = true;
		}
		_.each(parts, (el, i) => {
			if (el.includes('locale') && locale && !changed) {
				el = `${el.split('=')[0]}=${locale}`;
			}
			res += i === 0 ? `?${el}` : `&${el}`;
		});
		return `${res}`;
	},

	setDefaultLocale() {
		const locale = this.getLanguage();
		const defaultLocale = this.generalSettings.defaultLocale;

		if (!getQueryLocale()) {
			if (locale !== defaultLocale) {
				const {origin, pathname, hash} = window.location;
				window.location.href = `${origin}${pathname || ''}${this.getLocationSearch(defaultLocale)}${hash || ''}`;
			}
		}
	},

	loadSettings() {
		return new Promise((resolve, reject) => {
			axios.get('/midoffice/ibecorp-b2b/site/getSiteSettings')
				.then((result) => {
					const locale = result.data.result.locale;
					const storedLocale = this.getLanguage();

					const {logo} = result.data.result.visualSettings || {};
					if (logo) {
						this.setSiteLogo(logo);
					}

					window.ibe = window.ibe || {
						settings: result.data.result,
					};
					if (locale !== storedLocale) {
						this.setLanguage(locale);
						this.loadSettings().then(resolve).catch(reject);
					} else {
						this.set('siteSettings', result.data.result);
						this.showBanners();
					}
					resolve(result);
				})
				.catch(reject);
		});
	},

	showBanners() {
		const visualSettings = this.get('siteSettings').visualSettings;
		setTimeout(() => {
			if (visualSettings && visualSettings.topBlockPresent && visualSettings.topBlock) {
				Backbone.$('.l-layout__top-block').empty().append(visualSettings.topBlock);
			}
		}, 0);

		// if (visualSettings.leftBlockPresent) {
		// 	Backbone.$('.b-bnr-left').append(visualSettings.leftBlock);
		// }
		// if (visualSettings.rightBlockPresent) {
		// 	Backbone.$('.b-bnr-right').append(visualSettings.rightBlock);
		// }
	},

	loadCountries() {
		return new Promise((resolve, reject) => {
			axios.get('/midoffice/ibecorp-b2b/site/getCountries')
				.then((result) => {
					this.set('countries', result.data.result.countries);
					resolve(result);
				})
				.catch(reject);
		});
	},

	getSettings() {
		return this.get('siteSettings');
	},

	getSiteTypeSettings() {
		return this.get('siteTypeSettings');
	},

	setSiteTypeSettings(value) {
		this.set('siteTypeSettings', value);
	},

	getCountries() {
		return this.get('countries');
	},

	setProfile(profile) {
		STORE.set('profile', profile, 60 * 24 * 120);
		this.set('profile', profile);
	},

	getProfile() {
		return STORE.get('profile');
	},

	setSinglePersonSearch(value) {
		this.set('singlePersonSearch', value);
		// eslint-disable-next-line no-use-before-define
		STATE.getSearchModel().set('singlePersonSearch', value);
	},

	getSinglePersonSearch() {
		// eslint-disable-next-line no-use-before-define
		return this.get('singlePersonSearch') || STATE.getSearchModel().get('singlePersonSearch');
	},

	login(user) {
		const finalUser = Object.assign(
			{
				lastName: '',
				firstName: '',
				emails: [''],
			},
			user,
		);
		STORE.set('user', finalUser, 60 * 24 * 30);
		this.set('user', finalUser);
	},

	setLoginUser(user) {
		this.set('user', user);
		STORE.set('user', user, 60 * 24 * 30);
	},

	getLoginUser() {
		return this.get('user');
	},

	logout() {
		const lastProfile = this.getProfile();

		cookies.remove('IBECORP_B2B_AUTH_TOKEN_COOKIE');

		STORE.clear();

		_.each(_.compact([this.router, this.getPageView()]), (el) => {
			el.destroy();
		});

		if (this.checkSiteType('B2C')) {
			this.setLoginUser(null);
			window.location.hash = this.ROUTES.INDEX;
			this.APP.initialize();
		} else {
			this.showLogin();
			this.set(_.result(this, 'defaults'));
			this.setProfile(lastProfile);
		}
	},

	getLoggedInUser() {
		if (this.checkSiteType('B2C')) return STORE.get('user');

		const authToken = cookies.get('IBECORP_B2B_AUTH_TOKEN_COOKIE');

		if (!authToken || authToken === '') {
			this.logout();
		}
		return STORE.get('profile');
	},

	getDefaultCountryCode() {
		return this.getCountries().reduce((result, item) => {
			if (this.getSettings().generalSettings.defaultCitizenship.uid === item.uid) {
				result = item.phoneCode;
			}
			return result;
		}, '');
	},

	escapeReplace(unsafe) {
		return unsafe
			.replace(/&/g, '&amp;')
			.replace(/</g, '&lt;')
			.replace(/>/g, '&gt;')
			.replace(/"/g, '&quot;')
			.replace(/'/g, '&#039;');
	},

	escape(unsafe) {
		if (!unsafe) {
			return unsafe;
		}
		if (_.isArray(unsafe)) {
			unsafe.forEach((us, i) => {
				unsafe[i] = this.escapeReplace(us);
			});
			return unsafe;
		}
		return this.escapeReplace(unsafe);
	},

	pingServer() {
		const pingTimerId = this.get('_pingTimeId');
		if (pingTimerId != null) clearInterval(pingTimerId);
		this.set('_pingTimeId', setInterval(() => axios.get(`${this.getAxiosUrl()}/midoffice/ibecorp-b2b/cabinet/extendSession`), 600000));
	},

	getSearchResults() {
		if (this.tickets != null) {
			return this.tickets;
		}
		const routerTickets = STORE.get(this.ROUTES.AVIA_TICKETS);
		const tickets = routerTickets != null ? JSON.parse(LZString.decompressFromUTF16(routerTickets)) : [];
		this.tickets = tickets;
		return tickets;
	},

	getTrainsSearchResults() {
		if (this.trainsTickets != null) {
			return this.trainsTickets;
		}
		const routerTickets = STORE.get(this.ROUTES.RAILWAYS_TICKETS);
		const tickets = routerTickets != null ? JSON.parse(LZString.decompressFromUTF16(routerTickets)) : [];
		this.trainsTickets = tickets;
		return tickets;
	},

	setTrainsSearchResults(trainTickets) {
		this.trainsTickets = trainTickets;
		try {
			STORE.set(this.ROUTES.RAILWAYS_TICKETS, LZString.compressToUTF16(JSON.stringify(trainTickets)), 60);
		} catch (e) {
			if (e.name === 'QuotaExceededError') {
				STORE.set(this.ROUTES.RAILWAYS_TICKETS, null);
			} else {
				throw e;
			}
		}
	},

	setHotelSearchResults(hotels) {
		this.hotelsResult = hotels;
		try {
			STORE.set(this.ROUTES.HOTELS_OFFERS, LZString.compressToUTF16(JSON.stringify(hotels)), 60);
		} catch (e) {
			if (e.name === 'QuotaExceededError') {
				STORE.set(this.ROUTES.HOTELS_OFFERS, null);
			} else {
				throw e;
			}
		}
	},

	getHotelSearchResults() {
		if (this.hotelsResult != null) {
			return this.hotelsResult;
		}
		const routerResult = STORE.get(this.ROUTES.HOTELS_OFFERS);
		const result = routerResult != null ? routerResult : [];
		this.hotelsResult = result;
		return result;
	},

	getTransferSearchResults() {
		if (this.transfersResult != null) {
			return this.transfersResult;
		}
		const routerResult = STORE.get(this.ROUTES.TRANSFERS_OFFERS);
		const result = routerResult != null ? routerResult : [];
		this.transfersResult = result;
		return result;
	},

	getMatrixResult() {
		if (this.matrixFlights != null) {
			return this.matrixFlights;
		}

		const routerFlights = STORE.get(`${this.ROUTES.AVIA_TICKETS}.matrix`);
		const flights = routerFlights != null ? JSON.parse(LZString.decompressFromUTF16(routerFlights)) : {};
		this.matrixFlights = flights;

		return flights;
	},

	setSearchResult(tickets) {
		this.tickets = tickets;
		try {
			STORE.set(this.ROUTES.AVIA_TICKETS, LZString.compressToUTF16(JSON.stringify(tickets)), 60);
		} catch (e) {
			if (e.name === 'QuotaExceededError') {
				STORE.set(this.ROUTES.AVIA_TICKETS, null);
			} else {
				throw e;
			}
		}
	},

	setTrainsSearchResult(tickets) {
		this.trainsTickets = tickets;
		try {
			STORE.set(this.ROUTES.RAILWAYS_TICKETS, LZString.compressToUTF16(JSON.stringify(tickets)), 60);
		} catch (e) {
			if (e.name === 'QuotaExceededError') {
				STORE.set(this.ROUTES.RAILWAYS_TICKETS, null);
			} else {
				throw e;
			}
		}
	},

	setHotelsSearchResult(hotels) {
		this.hotelsResult = hotels;
		try {
			STORE.set(this.ROUTES.HOTELS_OFFERS, hotels);
		} catch (e) {
			if (e.name === 'QuotaExceededError') {
				STORE.set(this.ROUTES.HOTELS_OFFERS, null);
			} else {
				throw e;
			}
		}
	},

	setTransfersSearchResult(transfers) {
		this.transfersResult = transfers;
		try {
			STORE.set(this.ROUTES.TRANSFERS_OFFERS, transfers);
		} catch (e) {
			if (e.name === 'QuotaExceededError') {
				STORE.set(this.ROUTES.TRANSFERS_OFFERS, null);
			} else {
				throw e;
			}
		}
	},

	setFormState(key, modelJSON) {
		try {
			STORE.set(`formState:${key}`, LZString.compressToUTF16(JSON.stringify(modelJSON)));
		} catch (e) {
			if (e.name === 'QuotaExceededError') {
				STORE.set(`formState:${key}`, null);
			} else {
				throw e;
			}
		}
	},

	clearLocalStorageForRoute(routes = []) {
		_.each(routes, (route) => {
			if (!_.find(_.keys(this.ROUTES), (k) => this.ROUTES[k] === route)) return;
			STORE.remove(route);
		});
	},

	getFormState(key) {
		let state = STORE.get(`formState:${key}`);
		if (state) {
			state = JSON.parse(LZString.decompressFromUTF16(state));
		}

		return state;
	},

	setMatrixResult(flights) {
		this.matrixFlights = flights;
		STORE.set(`${this.ROUTES.AVIA_TICKETS}.matrix`, LZString.compressToUTF16(JSON.stringify(flights)), 60);
	},

	getQueryVariable(variable) {
		return getQueryVariable(variable);
	},

	setPreviousLocation(location) {
		try {
			STORE.set('previousLocation', location);
		} catch (e) {
			if (e.name === 'QuotaExceededError') {
				STORE.set('previousLocation', null);
			} else {
				throw e;
			}
		}
	},

	getPreviousLocation() {
		return STORE.get('previousLocation') || {};
	},

	set403_HANDLED(value) {
		this.set('IS_403_HANDLED', value);
	},

	get403_HANDLED() {
		return this.get('IS_403_HANDLED');
	},

	checkSiteType(type) {
		const {siteType} = this.getSiteTypeSettings();
		return siteType && siteType.uid === type;
	},

	setBookingInProcess(value) {
		this.set('bookingInProcess', value);
	},

	getBookingInProcess() {
		return this.get('bookingInProcess');
	},

	setIsMobileModalOpened(opened) {
		this.set('isMobileModalOpened', opened);
	},

	isMobileModalOpened() {
		return !!this.get('isMobileModalOpened');
	},

	shouldNavigate() {
		const isOpened = this.isMobileModalOpened();
		return !isOpened;
	},

	shouldNavigateToIndex(e) {
		const r = this.ROUTES;
		const routeList = [r.AVIA_PASSENGERS, r.TRANSFERS_PASSENGERS, r.HOTELS_PASSENGERS, r.RAILWAYS_PASSENGERS];
		const isGoingBackToPassengers = _.some(routeList, (route) => e.newURL.includes(route));
		return Backbone.history.fragment.indexOf(`${this.ROUTES.CABINET_ORDER}/`) !== -1 &&
			isGoingBackToPassengers && e.newURL === this.getPreviousLocation().href;
	},

	setSiteTitle(value) {
		this.set('siteTitle', value);
	},

	getSiteTitle() {
		return this.get('siteTitle');
	},

	setSiteLogo(value) {
		this.set('siteLogo', value);
	},

	getSiteLogo() {
		return this.get('siteLogo');
	},

	setIsTablePage(value) {
		this.set('isTablePage', value);
	},

	getIsTablePage() {
		return this.get('isTablePage');
	},

	checkViewport(mediaQueries) {
		if (!_.isArray(mediaQueries)) mediaQueries = [mediaQueries];
		return _.some(mediaQueries, (mq) => window.matchMedia(mq).matches);
	},

	disableFirstStep() {
		if (this.router && this.router.breadcrumbs) {
			_.each(_.keys(this.router.breadcrumbs.routes), (k) => {
				this.router.breadcrumbs.routes[k] = _.filter(this.router.breadcrumbs.routes[k], (el) => el !== 'INDEX');
			});
			$('.b-breadcrumbs__items [data-route="INDEX"]').remove();
		}
		if (this.router && this.router.routes) {
			if (this.router.routes[''] != null) delete this.router.routes[''];
			if (this.router.routes['/'] != null) delete this.router.routes['/'];
		}
	},

	setIsFromHash(value) {
		this.set('isFromHash', value);
	},

	getIsFromHash() {
		return this.get('isFromHash');
	},

	removeHeader() {
		$('.l-layout__header .b-header').remove();
	},

});

const STATE = new StateModel();

// Fix for ProvidePlugin which properly works only with old module definition
// eslint-disable-next-line no-undef
// module.exports = STATE;

export default STATE;
