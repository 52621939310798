import React, { createContext, createElement, ReactNode } from "react";
import { createRoot } from "react-dom/client";
import L10N from "./L10N";
import { resetAllStores } from "../../react/store";

export interface ReactAppGlobals {
	STATE: any;
	logger: any;
  L10N: any;
}

export const ReactAppGlobalContext = createContext({} as ReactAppGlobals);

const reactAppLogoutFunction = function() {
  resetAllStores();
}

export class ReactUtils {
	static renderReactApp(htmlElement: HTMLElement, reactComponent: React.FC) {
		const root = createRoot(htmlElement);
		root.render(
			createElement(
				ReactAppGlobalContext.Provider,
				{
					value: {
						STATE,
						logger,
            L10N
					},
				},
				createElement(reactComponent, {})
			)
		);
    document.removeEventListener('react_app_logout', reactAppLogoutFunction);
    document.addEventListener('react_app_logout', reactAppLogoutFunction);
	}

	static classNames(classes?: any[]) {
		if (!classes || !Array.isArray(classes)) {
			return "";
		}
		return classes.filter((className: unknown) => !!className).join(" ");
	}

  static modelToParameters(model: unknown) {
    return {
      parameters: model
    }
  }

}
