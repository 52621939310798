module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


	function isRoundTrip(flight) {
		if (flight != null && !_.isEmpty(flight.legs)) {
			var firstSegments = flight.legs[0].segments;
			var lastSegments = flight.legs[flight.legs.length - 1].segments;
			var isMissingRequiredField = false;
			_.each([firstSegments, lastSegments], function(segments) {
               if ( _.some(['departureCity', 'arrivalCity'], function(key) { return segments[0][key] == null || segments[segments.length - 1][key] == null})) {
                   isMissingRequiredField = true;
               }
            });
			if (isMissingRequiredField) return false;
			var firstFlightDeparture = firstSegments[0].departureCity.uid;
			var firstFlightArrival = firstSegments[firstSegments.length - 1].arrivalCity.uid;
			var lastFlightDeparture = lastSegments[0].departureCity.uid;
			var lastFlightArrival = lastSegments[lastSegments.length - 1].arrivalCity.uid;
			return (flight.legs.length == 2 && (firstFlightDeparture == lastFlightArrival) && (firstFlightArrival == lastFlightDeparture));
		} else {
			return false;
		}
	};

	var _isRoundTrip = isRoundTrip(flight);
	_.each(flight.legs, function (leg, i) { ;
__p += '\n		<div class="b-leg__wrapper">\n			';
 if (_isRoundTrip === true) { ;
__p += '\n				<div class="b-leg__title mobile-hide">\n					<span class="b-leg__direction">' +
((__t = ( (i === 0) ? L10N.get("nearestMatrix.to") : L10N.get("nearestMatrix.from") )) == null ? '' : __t) +
':\n					</span>\n					<span class="b-leg__duration">' +
((__t = ( Formatter.formatDurationFromPattern(leg.duration) )) == null ? '' : __t) +
'</span>\n				</div>\n			';
 } else if (flight.legs.length >= 2) { ;
__p += '\n				<div class="b-leg__title mobile-hide">\n					<span class="b-leg__direction">\n                        ';
 if (_.first(leg.segments).departureCity && _.first(leg.segments).arrivalCity) { ;
__p += '\n                            ' +
((__t = ( _.first(leg.segments).departureCity.caption )) == null ? '' : __t) +
' - ' +
((__t = ( _.last(leg.segments).arrivalCity.caption )) == null ? '' : __t) +
'\n                        ';
 } else { ;
__p += ' - ';
 } ;
__p += '\n					</span>\n				</div>\n			';
 } ;
__p += '\n            ';
 if (_isRoundTrip) { ;
__p += '\n                <span class="js-copy-content copy-content">' +
((__t = ( i === 0 ? '\n' + L10N.get('trains.there') + '\n' : '\n' + L10N.get('trains.backward') + '\n' )) == null ? '' : __t) +
'</span>\n            ';
 } ;
__p += '\n\n      ';

      // UTS-1115
      var showStopInfo = true;
      var firstSegment = leg.segments[0];
      var lastSegment = leg.segments[leg.segments.length - 1];



      if (firstSegment && lastSegment) {       
        const firstSegmentDepAirport = firstSegment.departureAirport?.uid;
        const firstSegmentArrAirport = firstSegment.arrivalAirport?.uid;
        const lastSegmentDepAirport = lastSegment.departureAirport?.uid;
        const lastSegmentArrAirport = lastSegment.arrivalAirport?.uid;
        if (firstSegmentDepAirport === lastSegmentArrAirport && firstSegmentArrAirport === lastSegmentDepAirport) {
          showStopInfo = false;
        }
      }

      ;

 _.each(leg.segments, function(segment, i) {
				var segmentDateDeparture = segment.departureDate ? new Time(segment.departureDate) : null;
				var segmentDateArrival = segment.arrivalDate ? new Time(segment.arrivalDate) : null;
				var segmentTravelTime = Time.getZeroTime().add(segment.travelDuration, 'm');
				var iconNameFromCompany = segment.airline && segment.airline.codeSystem !== 'IATA' ? segment.airline.airlineCode + '-' + segment.airline.codeSystem : segment.airline && segment.airline.airlineCode || 'noSegmentAirlineField';
				const copySegmentInfoParts = [];
				let copySegmentResult = '';
				let luggageExists = false;
				let baggageExists = false;

                let baggageClsName = '';
                let refundClsName = '';

                function isFREE(obj) {
                    return !_.isEmpty(obj) && _.every(obj, function(el) {
                        return _.isObject(el) && el.nil !== true;
                    });
                }

                var flightServices = flight.servicesStatuses || {};
                var services = segment.servicesDetails;
                var servicesDetails = segment.servicesDetails;
                var cabinLuggageState = 'OFF';
				if (servicesDetails) {
					cabinLuggageState  = (isFREE(servicesDetails.freeCabinLuggage) || isFREE(servicesDetails.paidCabinLuggage)) ? 'FREE' : 'OFF';
				}
 				
        
				if (i > 0 && showStopInfo) {
                    var prevArrivalDate = leg.segments[i - 1].arrivalDate;
                    var stopDurationStr = "";
                    var stopType = 'short';
                    if (prevArrivalDate) {
                        var prevStopDuration = new Time(prevArrivalDate);
                        var stopDuration = segmentDateDeparture.subtract(prevStopDuration.getMoment().toDate().getTime(), 'ms').getMoment().toDate().getTime() / 1000 / 60;
    
                        if (stopDuration > 2*60) {
                            stopType = 'medium';
                        }
    
                        if (stopDuration > 6*60) {
                            stopType = 'long';
                        } 
                        
                        stopDurationStr = Formatter.formatDurationFromPattern(stopDuration);
                    }
                    ;
__p += '\n\n					<div class="b-leg-stop b-stop b-stop-' +
((__t = ( stopType )) == null ? '' : __t) +
'">\n						<div class="b-stop__locations">\n							<span class="b-stop__locations-transfer">' +
((__t = ( L10N.get("flightlegs.stop" + stopType) )) == null ? '' : __t) +
': </span>\n							<span class="b-stop__locations-city">\n								';
 if (segment.departureCity) { ;
__p += '\n								' +
((__t = ( segment.departureCity.caption )) == null ? '' : __t) +
',\n								';
 } ;
__p += '\n							</span>\n							<span class="b-stop__locations-airport">\n								';
 if (segment.departureAirport) { ;
__p += '\n									' +
((__t = ( segment.departureAirport.caption )) == null ? '' : __t) +
'\n									(' +
((__t = ( segment.departureAirport.uid )) == null ? '' : __t) +
')\n								';
 } ;
__p += '\n							</span>\n						</div>\n						<div class="b-stop__duration">' +
((__t = ( stopDurationStr )) == null ? '' : __t) +
'</div>\n					</div>\n                    <span class="js-copy-content copy-content">&#10;&#13;<strong>' +
((__t = ( L10N.get("flightlegs.stop" + stopType) )) == null ? '' : __t) +
'</strong>: ' +
((__t = ( segment.departureCity != null && segment.departureCity.caption )) == null ? '' : __t) +
', ' +
((__t = ( segment.departureAirport != null ? (segment.departureAirport.caption + " (" + segment.departureAirport.uid + ")") : "" )) == null ? '' : __t) +
' ' +
((__t = ( stopDurationStr )) == null ? '' : __t) +
'&#10;&#13;</span>\n                ';

				}
				if (segment.techStopInfos != null && segment.techStopInfos.length > 0) { 
					const departureDate = new Time(segment.techStopInfos[0].departureDate);
					const arrivalDate = new Time( segment.techStopInfos[0].arrivalDate); ;
__p += '\n					<div class="b-leg-stop b-stop b-stop-long">\n						<div class="b-stop__locations">\n							<span class="b-stop__locations-transfer">' +
((__t = ( L10N.get('flightlegs.techStop'))) == null ? '' : __t) +
': </span>\n							<span class="b-stop__locations-city">\n								' +
((__t = ( segment.techStopInfos[0].location.caption )) == null ? '' : __t) +
'\n							</span>\n							<span class="b-stop__locations-airport">\n								(' +
((__t = ( segment.techStopInfos[0].location.uid )) == null ? '' : __t) +
')\n							</span>\n						</div>\n						<div class="b-stop__duration">' +
((__t = ( arrivalDate.format('LT') )) == null ? '' : __t) +
' - ' +
((__t = ( departureDate.format('LT') )) == null ? '' : __t) +
' (' +
((__t = ( Formatter.formatDurationFromPattern(segment.techStopInfos[0].duration) )) == null ? '' : __t) +
')</div>\n						\n					</div>\n                    <span class="js-copy-content copy-content">' +
((__t = ( L10N.get('flightlegs.techStop') )) == null ? '' : __t) +
': ' +
((__t = ( segment.techStopInfos[0].location.caption )) == null ? '' : __t) +
'</span>\n\n				';
 } ;
__p += '\n\n				<div class="b-leg b-leg-' +
((__t = ( i )) == null ? '' : __t) +
'">\n                    <div class="b-leg__mobile">\n                        <div class="b-leg__mobile-header">\n                            ';
 if (segment.departureCity) { ;
__p += '\n                                ' +
((__t = ( segment.departureCity.caption )) == null ? '' : __t) +
'\n                            ';
 } ;
__p += '\n                            -\n                            ';
 if (segment.arrivalCity) { ;
__p += '\n                                ' +
((__t = ( segment.arrivalCity.caption )) == null ? '' : __t) +
',\n                            ';
 } ;
__p += '\n                            ' +
((__t = ( segmentDateDeparture && segmentDateDeparture.toMonthDay() || '' )) == null ? '' : __t) +
'\n                        </div>\n                        <div class="b-leg__mobile-info">\n                            <div class="b-leg__mobile-company-icon-wrapper">\n                                <div class="b-leg__mobile-company-icon" style="background-image: url(\'/assets/img/logos/logos-m/' +
((__t = ( iconNameFromCompany )) == null ? '' : __t) +
'-black.svg\')"></div>\n                            </div>\n                            <div class="b-leg__mobile-carrier-info">\n                                <span class="b-leg__mobile-company-name">' +
((__t = ( segment.airline && _.escape(segment.airline.caption) )) == null ? '' : __t) +
'</span>\n                                <span class="b-leg__mobile-carrier-number">\n                                    ';
 if (segment.aircraft) { ;
__p += '\n                                        ' +
((__t = ( segment.aircraft.caption )) == null ? '' : __t) +
'\n                                    ';
 } ;
__p += '\n                                </span>\n                                <span class="b-leg__mobile-flight-perform">\n                                    ';
 if (segment.airline) { ;
__p += '\n                                        ' +
((__t = ( L10N.get('ticket.flightBy') )) == null ? '' : __t) +
': <span>' +
((__t = ( segment.airline.caption )) == null ? '' : __t) +
'</span>\n                                    ';
 } ;
__p += '\n                                </span>\n                            </div>\n                            <div class="b-leg__mobile-flight-number">\n                                <span title="' +
((__t = ( L10N.get('ticket.flightNumber') )) == null ? '' : __t) +
'">' +
((__t = ( segment.airline && segment.airline.airlineCode + segment.flightNumber  )) == null ? '' : __t) +
'</span>\n                            </div>\n                        </div>\n                        <div class="b-leg__mobile-dates">\n					        <span class="b-leg__mobile-date-time">\n						        ' +
((__t = ( segmentDateDeparture && segmentDateDeparture.toShortTime() || '----' )) == null ? '' : __t) +
'\n					        </span>\n                            ';
 if (segment.travelDuration != null) { ;
__p += '\n                                <span class="b-leg__mobile-duration">' +
((__t = ( Formatter.formatDurationFromPattern(segment.travelDuration) )) == null ? '' : __t) +
'</span>\n                            ';
 } ;
__p += '\n                                <span class="b-leg__mobile-date-time">\n						    ' +
((__t = ( segmentDateArrival && segmentDateArrival.toShortTime() || '----' )) == null ? '' : __t) +
'\n					        </span>\n                        </div>\n                        <div class="b-leg__mobile-route-line"></div>\n                        <div class="b-leg__mobile-route-info">\n					        <span class="b-leg__mobile-route-location">\n					        	<div>\n                                    ';
 if (segment.departureCity) { ;
__p += '\n                                        ' +
((__t = ( segment.departureCity.caption )) == null ? '' : __t) +
'\n                                    ';
 } ;
__p += '\n                                </div>\n                                <div>\n                                    ';
 if (segment.departureAirport) { ;
__p += '\n                                        ' +
((__t = ( segment.departureAirport.uid )) == null ? '' : __t) +
', ' +
((__t = ( segment.departureAirport.caption )) == null ? '' : __t) +
'\n                                    ';
 } ;
__p += '\n                                </div>\n					        </span>\n                            <span class="b-leg__mobile-route-location">\n						        <div>\n                                    ';
 if (segment.arrivalCity) { ;
__p += '\n                                        ' +
((__t = ( segment.arrivalCity.caption )) == null ? '' : __t) +
'\n                                    ';
 } ;
__p += '\n                                </div>\n                                <div>\n                                    ';
 if (segment.arrivalAirport) { ;
__p += '\n                                        ' +
((__t = ( segment.arrivalAirport.uid )) == null ? '' : __t) +
', ' +
((__t = ( segment.arrivalAirport.caption )) == null ? '' : __t) +
'\n                                    ';
 } ;
__p += '\n                                </div>\n					        </span>\n                        </div>\n                    </div>\n					<div class="b-leg__company-logo g-icon"\n						style="background-image: url(\'/assets/img/logos/' +
((__t = ( iconNameFromCompany )) == null ? '' : __t) +
'-black.svg\')"\n						data-toggle="tooltip" data-placement="bottom" title="' +
((__t = ( _.escape(segment.airline && segment.airline.caption) )) == null ? '' : __t) +
'"></div>\n					<div class="b-leg__flights">\n						<div class="b-leg__flight">\n							';
 if (segmentDateDeparture) { ;
__p += '\n								<div class="b-leg__date">\n									<span class="b-leg__date-time">' +
((__t = ( segmentDateDeparture.toShortTime() )) == null ? '' : __t) +
'</span>\n									<span class="b-leg__date-info">' +
((__t = ( segmentDateDeparture.toShortDate() )) == null ? '' : __t) +
'</span>\n								</div>\n                                ';
 copySegmentInfoParts.push(segmentDateDeparture.format('DD.MM')) ;
__p += '\n							';
 } ;
__p += '\n							<div class="b-leg__flight-info">\n								<div class="b-leg__flight-info-destination">\n									<span class="b-leg__flight-info-city">\n										';
 if (segment.departureCity) { ;
__p += '\n											' +
((__t = ( segment.departureCity.caption )) == null ? '' : __t) +
',\n										';
 } ;
__p += '\n									</span>\n									<span class="b-leg__flight-info-airport">\n										';
 if (segment.departureAirport) { ;
__p += '\n											' +
((__t = ( segment.departureAirport.caption )) == null ? '' : __t) +
'\n                                            (' +
((__t = ( segment.departureAirport.uid )) == null ? '' : __t) +
')\n										';
 } ;
__p += '\n									</span>\n								</div>\n                                ';
 copySegmentInfoParts.push(segment.airline && segment.airline.airlineCode + segment.flightNumber) ;
__p += '\n                                ';
 if (segment.departureCity) {
                                    copySegmentInfoParts.push(segment.departureCity.caption + ', ');
                                 } ;
__p += '\n                                ';
 if (segment.departureAirport) {
                                    copySegmentInfoParts.push(segment.departureAirport.caption);
                                } ;
__p += '\n                                ';
 if (segmentDateDeparture) {
                                    copySegmentInfoParts.push(segmentDateDeparture.toShortTime(), '-')
								} ;
__p += '\n								<div class="b-leg__flight-info-number">\n									<span data-toggle="tooltip" data-placement="bottom"\n										title="' +
((__t = ( L10N.get('ticket.flightNumber') )) == null ? '' : __t) +
'">' +
((__t = ( segment.airline && segment.airline.airlineCode + segment.flightNumber )) == null ? '' : __t) +
'</span>,\n									';
 if (segment.aircraft != null) { ;
__p += '\n									<span data-toggle="tooltip" data-placement="bottom"\n										title="' +
((__t = ( L10N.get('ticket.aircraftNumber') )) == null ? '' : __t) +
'">' +
((__t = ( segment.aircraft && segment.aircraft.caption || '-' )) == null ? '' : __t) +
'</span>\n									';
 } ;
__p += '\n								</div>\n								';

									var operatingAirlines = _.compact([segment.operatingAirline && segment.operatingAirline.caption, segment.operatingAirlineCompanyName]);

									if (_.size(operatingAirlines) > 0 && operatingAirlines.indexOf(segment.airline && segment.airline.caption) === -1) { ;
__p += '\n										<div class="b-leg__flight-info-perfom">' +
((__t = ( L10N.get('ticket.flightBy') )) == null ? '' : __t) +
': <span class="b-leg__flight-info-perfom-airlines">' +
((__t = ( operatingAirlines.join(', ') )) == null ? '' : __t) +
'</span></div>\n                                        <span class="js-copy-content copy-content">' +
((__t = ( L10N.get('ticket.flightBy') )) == null ? '' : __t) +
': ' +
((__t = ( operatingAirlines.join(', ') )) == null ? '' : __t) +
'</span>\n									';
 } ;
__p += '\n									<div class="b-leg__flight-description">\n										' +
((__t = ( L10N.get("flightlegs.classOfService") )) == null ? '' : __t) +
': ' +
((__t = ( segment.classOfService.caption )) == null ? '' : __t) +
'\n										<span class="action-link b-flight-services__rule-carrier-open" data-segmentid="' +
((__t = ( flight.uid )) == null ? '' : __t) +
'">\n											' +
((__t = ( (segment.servicesDetails && segment.servicesDetails.fareBasis ? _.values(segment.servicesDetails.fareBasis).join(', ') : '') )) == null ? '' : __t) +
'\n										</span>\n									</div>\n							</div>\n						</div>\n						<div class="b-leg__flight">\n							';
 if (segmentDateArrival) { ;
__p += '\n								<div class="b-leg__date">\n									<span class="b-leg__date-time">' +
((__t = ( segmentDateArrival.toShortTime() )) == null ? '' : __t) +
'</span>\n									<span class="b-leg__date-info">' +
((__t = ( segmentDateArrival.toShortDate() )) == null ? '' : __t) +
'</span>\n								</div>\n							';
 } ;
__p += '\n							<div class="b-leg__flight-info">\n								<div class="b-leg__flight-info-destination">\n									<span class="b-leg__flight-info-city">\n										';
 if (segment.arrivalCity) { ;
__p += '\n											' +
((__t = ( segment.arrivalCity.caption )) == null ? '' : __t) +
',\n										';
 } ;
__p += '\n									</span>\n									<span class="b-leg__flight-info-airport">\n										';
 if (segment.arrivalAirport) { ;
__p += '\n											' +
((__t = ( segment.arrivalAirport.caption )) == null ? '' : __t) +
'\n											(' +
((__t = ( segment.arrivalAirport.uid )) == null ? '' : __t) +
')\n										';
 } ;
__p += '\n									</span>\n								</div>\n                                ';
 if (segment.arrivalCity) {
                                    copySegmentInfoParts.push(segment.arrivalCity.caption + ', ');
                                } ;
__p += '\n                                ';
 if (segment.arrivalAirport) {
                                    copySegmentInfoParts.push(segment.arrivalAirport.caption);
                                } ;
__p += '\n                                ';
 if (segmentDateArrival) {
                                    copySegmentInfoParts.push(segmentDateArrival.format('DD.MM'))
								} ;
__p += '\n							</div>\n						</div>\n					</div>\n					<div class="b-leg__description">\n						';
 if (withServices === true && segment.servicesDetails) { ;
__p += '\n						<ul class="b-flight-services__options">\n							';
 if (!_.isEmpty(services.freeCabinLuggage) || !_.isEmpty(services.paidCabinLuggage)) {
                                luggageExists = true;
                            ;
__p += '\n								<li data-toggle="tooltip" data-placement="bottom" data-html="true"\n									data-original-title="' +
((__t = ( _.escape(require('./b-ticket-luggage.ejs')({ segments: [segment], type: 'luggage' })) )) == null ? '' : __t) +
'"\n									class="b-flight-services__option g-icon-option-cabin-luggage-' +
((__t = ( cabinLuggageState )) == null ? '' : __t) +
'-black"></li>\n							';
 } else { ;
__p += ' \n								<li data-toggle="tooltip" data-placement="bottom" data-html="true"\n									data-original-title="' +
((__t = ( _.escape(require('./b-ticket-luggage.ejs')({ segments: [segment], type: 'luggage' })) )) == null ? '' : __t) +
'"\n									class="b-flight-services__option g-icon-option-cabin-luggage-grey"></li>\n                            ';
 } ;
__p += '\n\n							';
 if (flightServices.baggage != null) {
                                baggageExists = true;
                                baggageClsName = flightServices.baggage.uid === 'OFF' ?
                                    'g-icon-option-baggage-grey' : 'g-icon-option-baggage-' + flightServices.baggage.uid + '-black';
                            ;
__p += '\n								<li\n									data-toggle="tooltip"\n                                    data-placement="bottom"\n                                    data-html="true"\n									data-original-title="' +
((__t = ( _.escape(require('./b-ticket-luggage.ejs')({ segments: [segment], type: 'baggage' })) )) == null ? '' : __t) +
'"\n									class="b-flight-services__option ' +
((__t = ( baggageClsName )) == null ? '' : __t) +
'"\n                                >\n                                </li>\n							';
 } ;
__p += '\n\n							';
 if (flightServices.exchange != null || flightServices.refund != null) {
								var states = _.compact([(flightServices.exchange || {}).uid, (flightServices.refund || {}).uid]);
								var state = 'FREE';

								var hasFreeExchange = false;
								_.each(_.values(flight.exchange), function(el) {
									if (_.some(_.values(el), function(s) { return s === true; })) {
										hasFreeExchange = true;
									}
								});

								var hasFreeRefund = false;
								_.each(_.values(flight.refund), function(el) {
									if (_.some(_.values(el), function(s) { return s === true; })) {
										hasFreeRefund = true;
									}
								});

								if (!hasFreeExchange && !hasFreeRefund) {
									state = 'OFF';
								}

								if ((states[0] === 'OFF' && !states[1]) || (states[0] === 'OFF' && states[0] === states[1])) {
									state = 'OFF';
								}
                                refundClsName = state === 'OFF' ?
                                    'g-icon-option-refund-exchange-grey' : 'g-icon-option-refund-exchange-' + state + '-black';
							;
__p += '\n                                <li\n                                    data-toggle="tooltip"\n                                    data-placement="bottom"\n                                    data-html="true"\n                                    data-original-title="' +
((__t = ( _.escape(require('./b-ticket-refund-exchange.ejs')({ services: {refund: flight.refund || {}, exchange: flight.exchange || {}} })) )) == null ? '' : __t) +
'"\n                                    class="b-flight-services__option ' +
((__t = ( refundClsName )) == null ? '' : __t) +
'"\n                                >\n                                </li>\n							';
 } ;
__p += '\n						</ul>\n						';
 } ;
__p += '\n							<div>\n								' +
((__t = ( L10N.get("flightlegs.classOfService") )) == null ? '' : __t) +
': ' +
((__t = ( segment.classOfService.caption )) == null ? '' : __t) +
'\n								<span class="b-flight-services__branded-fares ' +
((__t = ( isOnline ? 'action-link b-flight-services__rule-carrier-open' : '' )) == null ? '' : __t) +
'"\n									  data-token="' +
((__t = ( flight.uid || flightToken )) == null ? '' : __t) +
'">\n                                ' +
((__t = ( (segment.servicesDetails && segment.servicesDetails.fareBasis ? _.values(segment.servicesDetails.fareBasis).join(', ') : '') )) == null ? '' : __t) +
'\n								</span>\n							</div>\n					</div>\n					<div class="b-leg__duration">\n						';
 if (segment.travelDuration != null) { ;
__p += '\n							' +
((__t = ( Formatter.formatDurationFromPattern(segment.travelDuration) )) == null ? '' : __t) +
'\n						';
 } ;
__p += '\n\n						<!-- FOR 1280px -->\n						';
 if (withServices === true && segment.servicesDetails) { ;
__p += '\n                            <ul class="b-flight-services__options">\n                                ';
 if (!_.isEmpty(services.freeCabinLuggage) || !_.isEmpty(services.paidCabinLuggage)) { ;
__p += '\n                                    <li data-toggle="tooltip" data-placement="bottom" data-html="true"\n                                        data-original-title="' +
((__t = ( _.escape(require('./b-ticket-luggage.ejs')({ segments: [segment], type: 'luggage' })) )) == null ? '' : __t) +
'"\n                                        class="b-flight-services__option g-icon-option-cabin-luggage-' +
((__t = ( cabinLuggageState )) == null ? '' : __t) +
'-black"></li>\n                                ';
 } else { ;
__p += ' \n                                    <li data-toggle="tooltip" data-placement="bottom" data-html="true"\n                                        data-original-title="' +
((__t = ( _.escape(require('./b-ticket-luggage.ejs')({ segments: [segment], type: 'luggage' })) )) == null ? '' : __t) +
'"\n                                        class="b-flight-services__option g-icon-option-cabin-luggage-grey"></li>\n                                ';
 } ;
__p += '\n\n                                ';
 if (flightServices.baggage != null) {;
__p += '\n                                    <li data-toggle="tooltip" data-placement="bottom" data-html="true"\n                                        data-original-title="' +
((__t = ( _.escape(require('./b-ticket-luggage.ejs')({ segments: [segment], type: 'baggage' })) )) == null ? '' : __t) +
'"\n                                        class="b-flight-services__option ' +
((__t = ( baggageClsName )) == null ? '' : __t) +
'"></li>\n                                ';
 };
__p += '\n\n                                ';
 if (!_.isEmpty(flightServices.exchange) || !_.isEmpty(flightServices.refund)) { ;
__p += '\n                                    <li data-toggle="tooltip" data-placement="bottom" data-html="true"\n                                        data-original-title="' +
((__t = ( _.escape(require('./b-ticket-refund-exchange.ejs')({ services: {refund: flight.refund || {}, exchange: flight.exchange || {}} })) )) == null ? '' : __t) +
'"\n                                        class="b-flight-services__option ' +
((__t = ( refundClsName )) == null ? '' : __t) +
'"></li>\n                                ';
 } ;
__p += '\n                            </ul>\n						';
 } ;
__p += '\n						<!-- /FOR 1280px -->\n					</div>\n				</div>\n                <span class="js-copy-content copy-content">' +
((__t = ( copySegmentInfoParts.join(' ') )) == null ? '' : __t) +
'</span>\n                ';
 if (segment.aircraft != null) { ;
__p += '<span class="js-copy-content copy-content">' +
((__t = ( L10N.get('ticket.aircraftNumber') )) == null ? '' : __t) +
': ' +
((__t = ( segment.aircraft && segment.aircraft.caption || '-' )) == null ? '' : __t) +
'</span>';
 } ;
__p += '\n                ';
 if (segmentDateArrival) { ;
__p += ' <span class="js-copy-content copy-content">' +
((__t = ( L10N.get('cabinet.orders.arrivalDate') )) == null ? '' : __t) +
' ' +
((__t = ( segmentDateArrival.toShortTime() )) == null ? '' : __t) +
' ' +
((__t = ( segmentDateArrival.format('DD.MM') )) == null ? '' : __t) +
' </span> ';
 } ;
__p += '\n                ';
 if (luggageExists) { ;
__p += '<span class="js-copy-content copy-content"><span data-find-copy-in-tooltip data-copy-title-from=".b-leg-' +
((__t = ( i )) == null ? '' : __t) +
' .b-flight-services__option.g-icon-option-cabin-luggage-' +
((__t = ( cabinLuggageState )) == null ? '' : __t) +
'-black"></span></span>';
 } ;
__p += '\n                ';
 if (baggageExists) { ;
__p += '<span class="js-copy-content copy-content"><span data-find-copy-in-tooltip data-copy-title-from=".b-leg-' +
((__t = ( i )) == null ? '' : __t) +
' .b-flight-services__option.g-icon-option-baggage-' +
((__t = ( flightServices.baggage.uid )) == null ? '' : __t) +
'-black"></span></span>';
 } ;
__p += '\n                <span class="js-copy-content copy-content">' +
((__t = ( L10N.get("flightlegs.classOfService") )) == null ? '' : __t) +
': ' +
((__t = ( segment.classOfService.caption )) == null ? '' : __t) +
'</span>\n                ';
 if (segment.travelDuration != null) { ;
__p += ' <span class="js-copy-content copy-content">' +
((__t = ( L10N.get("filters.travelTime") )) == null ? '' : __t) +
': ' +
((__t = ( Formatter.formatDurationFromPattern(segment.travelDuration) )) == null ? '' : __t) +
'</span> ';
 } ;
__p += '\n			';
 }); ;
__p += '\n		</div>\n';
 });
;
__p += '\n';

}
return __p
}