'use strict';

import BasePageView from '@/pages/base.page-view';
import CabinetMenu from '@/blocks/pages/b-cabinet/b-cabinet-menu/index';
import { ReactUtils } from '../../utils/react-utils';
import NewsPage from '../../../react/pages/news/NewsPage';

export default BasePageView.extend({

	initialize() {
		this.render();
		this.ui.sidebar.append(new CabinetMenu().$el);
		this.ui.content.append('<div class="react-news-page" />');
		this.initReactComponents();
	},

	initReactComponents() {
		ReactUtils.renderReactApp(this.ui.content.find('.react-news-page').get(0), NewsPage);
	},

}, {
	load() {
		return Promise.resolve({});
	},
});
