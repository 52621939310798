module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="b-ticket-popup-offers">' +
((__t = ( L10N.get('hotels.toAgreeShort') )) == null ? '' : __t) +
': ' +
((__t = ( data.length )) == null ? '' : __t) +
'</div>';

}
return __p
}