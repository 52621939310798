// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import $ from 'jquery';
import BaseView from '@/classes/base.view';
import template from './template.ejs';
import templatePriceClient from './template-price-client.ejs';
import CabinetMenu from '@/blocks/pages/b-cabinet/b-cabinet-menu/index';

import axios from 'axios';
import NewsBlock from './react/NewsBlock';
import { ReactUtils } from '../../../utils/react-utils';

export default BaseView.extend({

	template,

	events: {
		'click .b-header__info-menu-button': 'toggleMenu',
		'click .b-header__info-search-button': 'redirectToSearchPage',
	},

	ui: {
		additionalPriceContainer: '.b-header__additional-client',
		newsBlock: '.react-news-block',
	},

	el: '.l-layout__header',

	profile: null,

	initialize(options) {
		this.balanceInfo = options.balanceInfo;
		this.profile = STATE.getProfile();
		this.$el.empty();
		this.render();
		this.initReactComponents();
	},
  
	initReactComponents() {
		ReactUtils.renderReactApp(this.ui.newsBlock.get(0), NewsBlock);
	},

	getRootURL() {
		return `${window.location.origin}${window.location.path ? window.location.path : ''}${window.location.search}`;
	},

	setAdditionalOrder(order = {}) {
		const $container = this.$el.find('.b-header__additional-order');

		if (!_.isEmpty(order)) {
			const $element = $(`<span class="b-header__additional-order-number">${order.number}</span>`);

			if (order.bookingUid != null) {
				$element.click((e) => {
					if (e != null) {
						e.preventDefault();
					}

					STATE.navigate([STATE.ROUTES.CABINET_ORDER, order.bookingUid].join('/'));
				});
			}

			$container.html($element).append(` - <i class="g-icon-basket">${order.serviceCount}</i>`);
		} else {
			$container.empty();
		}
	},

	setAdditionalClient(clientOrganization) {
		if (!clientOrganization) {
			return;
		}

		axios.post('/midoffice/ibecorp-b2b/cabinetNew/getBalancesCompany', {
			parameters: { clientOrganization },
		}).then((r) => {
			const { result } = r.data;
			this.ui.additionalPriceContainer.html(templatePriceClient({
				balanceInfo: result,
			}));
		});
	},

	resetAdditionalClient() {
		this.ui.additionalPriceContainer.empty();
	},

	redirectToSearchPage() {
		STATE.navigate(STATE.ROUTES.INDEX);
	},

	toggleMenu() {
		this.$('.b-header__info-menu-button').toggleClass('close');
		$('.l-layout').removeClass('show-filters').toggleClass('show-menu');

		const SIDEBAR_CONTAINER = $('.l-layout__content-side');
		if (SIDEBAR_CONTAINER.is(':empty') || SIDEBAR_CONTAINER.children('.b-cabinet-menu').length === 0) {
			SIDEBAR_CONTAINER.append(new CabinetMenu().$el).children('.b-cabinet-menu').addClass('b-menu--mobile');
		}
		STATE.rearrangeHeaderInfoToMenu();
	},

});
